import React from "react";
import { useLocation } from "react-router-dom";

const NotFound = () => {
  const key_arg = useLocation().search;
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}>
      <h1
        style={{ fontSize: "5rem" }}
        className='mb-0'>
        404
      </h1>
      <p>Oops! Looks like you're getting lost.</p>
    </div>
  );
};

export default NotFound;
