import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  preset_filters: [
    "Block-Word",
    "Priority 1",
    "Priority 2",
    "Priority 3",
    "Priority 4",
    "Priority 5",
  ],
  list_filters: [],
  load_new_filter: true,
  active_filter: "0",
  index_active_filter: -1,
  run_list: [],
  keyword_list: [],
  keyword_list_string: [],
  keyword_list_with_filter: [],
  filtered_keyword_to_show: [],
  index_filter_keyword_fetch: -1,
  keyword_cursor: 0,
  selected_run: 0,
  loading_keywords: false,
  list_filter_loaded: false,
  register_filter: false,
  list_mapper: [],
  loadingMapper: false,
  saveMapper: false,
  savingMapper: false,
  max_keyword: false,
  group_keyword_list_to_show: [],
  address_group_keyword_list_to_show: [],
  destination_add_manual_item_main_map: [],
  removed_keyword_list: [],
  total_keyword_of_dataset: "-",
  total_keyword_processed_of_dataset: "-",
  first_load_filtered_keyword_attemp: null,
  finish_fetch_unfiltered: false,
  no_keyword_to_process: false,
  modal_main_map_location_correction: false,
  modal_image_viewer: false,
  loading_image_dataset: false,
  image_dataset_modal: [],
  image_dataset_modal_keyword: "",
  process_image_fethcer: false,
  keyword_highlight: "",
};

export const fetchRun = createAsyncThunk("keywords/fetchRun", async () => {
  return axios
    .get("https://api.arnaud-automation.xyz/get_run")
    .then((response) => response.data);
});

export const fetchKeywords = createAsyncThunk(
  "keywords/fetchKeywords",
  async (params) => {
    let endpoint =
      "https://api.arnaud-automation.xyz/get_keywords?id_run=" +
      params._id +
      "&offset=" +
      params.offset;
    if (params.mode) {
      endpoint = endpoint + "&mode=c";
    }
    return axios.get(endpoint).then((response) => {
      let rsp = {
        _id: params._id,
        data: response.data,
      };
      return rsp;
    });
  }
);

export const fetchDatasetKeywordsInformation = createAsyncThunk(
  "keywords/fetchDatasetKeywordsInformation",
  async (params) => {
    return axios
      .get(
        "https://api.arnaud-automation.xyz/dataset_keyword_information?id_run=" +
          params._id
      )
      .then((response) => {
        let rsp = {
          data: response.data,
        };
        return rsp;
      });
  }
);

export const fetchKeywordsNull = createAsyncThunk(
  "keywords/fetchKeywordsNull",
  async (params) => {
    return axios
      .get(
        "https://api.arnaud-automation.xyz/get_keywords_null?offset=" +
          params.offset
      )
      .then((response) => {
        let rsp = {
          data: response.data,
        };
        return rsp;
      });
  }
);

export const fetchKeywordsWithFilter = createAsyncThunk(
  "keywords/fetchKeywordsWithFilter",
  async (data) => {
    let post_data = JSON.stringify(data);
    return axios
      .post("https://api.arnaud-automation.xyz/get_keywords", post_data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        let rsp = {
          data: response.data,
        };
        return rsp;
      });
  }
);

export const fetchMapper = createAsyncThunk(
  "keywords/fetchMapper",
  async (params) => {
    return axios
      .get("https://api.arnaud-automation.xyz/get_map?id_run=" + params._id)
      .then((response) => {
        let rsp = {
          _id: params._id,
          data: response.data,
        };
        return rsp;
      });
  }
);

export const getFilters = createAsyncThunk(
  "keywords/getFilters",
  async (id_run) => {
    return axios
      .get("https://api.arnaud-automation.xyz/get_filters?id_run=" + id_run)
      .then((response) => response.data);
  }
);

export const registerFilter = createAsyncThunk(
  "keywords/registerFilter",
  async (data) => {
    let post_data = JSON.stringify(data);
    return axios
      .post("https://api.arnaud-automation.xyz/register_filter", post_data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return { data: response.data, post_data: post_data };
      });
  }
);

export const deleteFilter = createAsyncThunk(
  "keywords/deleteFilter",
  async (params) => {
    return axios
      .get(
        "https://api.arnaud-automation.xyz/delete_filter?id_filter=" +
          params.id_filter
      )
      .then((response) => {
        let rsp = {
          deleted_id: params.id_filter,
          data: response.data,
        };
        return rsp;
      });
  }
);

export const updateKeywordMapAddress = createAsyncThunk(
  "keywords/updateMapAddress",
  async (params) => {
    let post_data = JSON.stringify(params);

    return axios
      .post(
        "https://api.arnaud-automation.xyz/update_keyword_map_address",
        post_data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => response.data);
  }
);

export const mapperUpdate = createAsyncThunk(
  "keywords/updateMapper",
  async (params) => {
    // let post_data = { data: "Hello!" };
    let post_data = JSON.stringify(params);
    return axios
      .post("https://api.arnaud-automation.xyz/update_mapper", post_data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.data);
  }
);

export const fetchImageDataset = createAsyncThunk(
  "keywords/fetchImageDataset",
  async (data) => {
    let post_data = JSON.stringify(data.post_data);
    let path_address = JSON.stringify(data.path_address);
    return axios
      .post(
        "https://api.arnaud-automation.xyz/fetch_keyword_image",
        post_data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        let rsp = {
          data: response.data,
          data_path: path_address,
          data_info: data.post_data,
        };
        return rsp;
      });
  }
);

export const keywordSlice = createSlice({
  name: "keywords",
  initialState,
  reducers: {
    get_run_list: (state, action) => {},
    change_run: (state, action) => {
      state.keyword_list = [];
      state.keyword_list_with_filter = [];
      state.max_keyword = false;
      state.filtered_keyword_to_show = [];
      state.index_active_filter = -1;
      state.index_filter_keyword_fetch = -1;
      state.active_filter = "0";
      state.keyword_cursor = 0;
      state.total_keyword_processed_of_dataset = "-";
      state.total_keyword_of_dataset = "-";
      state.list_filter_loaded = false;
      state.list_filters = [];
      state.list_mapper = [];
      state.loading_keywords = false;
      state.finish_fetch_unfiltered = false;
      state.no_keyword_to_process = false;
      state.modal_image_viewer = false;
      state.image_dataset_modal = [];
      state.loading_image_dataset = false;
      state.process_image_fethcer = false;
      state.image_dataset_modal_keyword = "";
      state.modal_main_map_location_correction = false;
      state.keyword_highlight = "";
      let id_run = action.payload._id;
      if (id_run === "0") {
        state.selected_run = 0;
      } else {
        state.selected_run = id_run;
      }
    },
    reset_keyword_cursor: (state, anction) => {
      state.keyword_cursor = 0;
    },
    nav_cursor: (state, action) => {
      let opr = action.payload.opr;
      let keyword_loaded = action.payload.keyword_loaded;
      if (opr === 0) {
        // Decrease cursor
        state.keyword_cursor > 0
          ? (state.keyword_cursor = state.keyword_cursor - 1)
          : (state.keyword_cursor = 0);
      } else if (opr === 1) {
        // Increase cursor
        // state.keyword_cursor < state.keyword_list.length - 1
        //   ? (state.keyword_cursor = state.keyword_cursor + 1)
        //   : (state.keyword_cursor = state.keyword_list.length - 1);
        state.keyword_cursor < keyword_loaded - 1
          ? (state.keyword_cursor = state.keyword_cursor + 1)
          : (state.keyword_cursor = keyword_loaded - 1);
      }
      if (state.keyword_cursor + 1 > state.keyword_list.length) {
        // console.log("change to filtered keyword");
        // console.log(
        //   `Filtered Keyword index : ${
        //     state.keyword_cursor - state.keyword_list.length
        //   }`
        // );
        // console.log(current(state).keyword_list_with_filter);
        let filtered_keyword_index =
          state.keyword_cursor - state.keyword_list.length;

        state.keyword_list_with_filter.forEach((itm, idxa) => {
          if (filtered_keyword_index > -1) {
            if (itm.length > 0) {
              itm.forEach((kwrdf, idxb) => {
                if (filtered_keyword_index === 0) {
                  let obj = {
                    idxa: idxa,
                    idxb: idxb,
                    data: kwrdf,
                  };
                  state.filtered_keyword_to_show = obj;
                  state.index_active_filter = idxa;
                }
                filtered_keyword_index -= 1;
              });
            }
          }
        });
      }
    },
    insert_group_to_map: (state, action) => {
      let parents = action.payload.parents;
      let childItem = {
        k: action.payload.keyword,
        v: action.payload.volume,
      };
      let newGroup = {
        keyword_list: [],
        subs: [],
      };
      newGroup.keyword_list.push(childItem);
      let target = state.list_mapper;
      let targetChildren;

      for (let index = 0; index < parents.length; index++) {
        if (index === 0) {
          targetChildren = target.find(
            (el) => el.map_name === parents[index]
          ).children;
        }
      }
      targetChildren.push(newGroup);

      // Update map address of keyword
      let target_keyword = state.keyword_list.find(
        (itm) => itm._id === action.payload.id_keyword
      );
      if (!target_keyword) {
        let data = state.filtered_keyword_to_show;
        target_keyword = state.keyword_list_with_filter[data.idxa][data.idxb];
      }
      target_keyword["map_address"] = parents;
      state.saveMapper = true;
    },
    append_to_group: (state, action) => {
      let newItem = action.payload.group_item;
      let destination = action.payload.destination;
      let groupIndex = action.payload.g_index;

      // let target = state.list_mapper;
      let targetChildren = state.list_mapper;

      for (let index = 0; index < destination.length; index++) {
        if (index === 0) {
          targetChildren = targetChildren.find(
            (el) => el.map_name === destination[index]
          ).children;
        } else {
          targetChildren = targetChildren[destination[index]].subs;
        }
      }

      let exst = false;
      targetChildren[groupIndex].keyword_list.forEach((itm) => {
        if (itm.k === newItem.k) {
          exst = true;
        }
      });
      if (!exst) {
        targetChildren[groupIndex].keyword_list.push(newItem);
      }
      // Update map address of keyword
      // console.log(action.payload.id_keyword);
      let target_keyword = state.keyword_list.find(
        (itm) => itm._id === action.payload.id_keyword
      );
      if (!target_keyword) {
        let data = state.filtered_keyword_to_show;
        target_keyword = state.keyword_list_with_filter[data.idxa][data.idxb];
      }
      target_keyword["map_address"] = destination;
      state.saveMapper = true;
    },
    create_sub: (state, action) => {
      let newItem = action.payload.subs_item;
      let destination = action.payload.destination;
      let groupIndex = action.payload.g_index;

      let newGroup = {
        keyword_list: [],
        subs: [],
      };
      newGroup.keyword_list.push(newItem);

      let target = state.list_mapper;
      let targetChildren;
      // console.log(destination);

      for (let index = 0; index < destination.length; index++) {
        if (index === 0) {
          targetChildren = target.find(
            (el) => el.map_name === destination[index]
          ).children;
        } else {
          targetChildren = targetChildren[destination[index]].subs;
        }
      }
      targetChildren[groupIndex].subs.push(newGroup);

      // Update map address of keyword
      let target_keyword = state.keyword_list.find(
        (itm) => itm._id === action.payload.id_keyword
      );
      if (!target_keyword) {
        let data = state.filtered_keyword_to_show;
        target_keyword = state.keyword_list_with_filter[data.idxa][data.idxb];
      }
      target_keyword["map_address"] = destination;
      state.saveMapper = true;
    },
    set_index_active_filter: (state, action) => {
      // state.active_filter = action.payload.value;
      // state.keyword_list = [];
      // state.keyword_cursor = 0;
      state.index_active_filter = action.payload.value;
    },
    set_group_keyword_list_to_show: (state, action) => {
      state.group_keyword_list_to_show = action.payload.keyword_list;
      state.address_group_keyword_list_to_show = action.payload.address;
    },
    remove_keyword_from_map: (state, action) => {
      let item_index = action.payload.item_index;
      let keyword_target = action.payload.keyword_target;
      let map_list = state.list_mapper;
      let address = state.address_group_keyword_list_to_show;
      let root_map = map_list[address[0]];
      let target = root_map.children[address[1]];
      for (let i = 0; i < address.length; i++) {
        if (i > 1) {
          target = target.subs[address[i]];
        }
      }
      target.keyword_list.splice(item_index, 1);
      state.group_keyword_list_to_show.splice(item_index, 1);
      state.saveMapper = true;

      // Delete keyword map address from keyword_list slice
      let keyword_list = state.keyword_list;
      keyword_list.forEach((obj) => {
        if (obj.keyword === keyword_target) {
          obj.map_address = [];
        }
      });

      // if there is no one left keyword on the group
      if (target.keyword_list.length === 0) {
        let push_index = address[address.length - 1];
        let push_target = map_list;
        for (let i = 0; i < address.length - 1; i++) {
          if (i === 0) {
            push_target = push_target[address[i]];
          } else if (i === 1) {
            push_target = push_target.children[address[i]];
          } else {
            push_target = push_target.subs[address[i]];
          }
        }
        // if they have subs, merge one step up.
        if (target.subs.length > 0) {
          // Loop subs that will be inserted into push_target
          for (let x = 0; x < target.subs.length; x++) {
            if (push_target.children) {
              push_target.children.splice(push_index, 0, target.subs[x]);
            } else {
              push_target.subs.splice(push_index, 0, target.subs[x]);
            }
            push_index++;
          }
        }

        // Delete old group that been empty
        if (push_target.children) {
          push_target.children.splice(push_index, 1);
        } else {
          push_target.subs.splice(push_index, 1);
        }
      }
    },
    update_filter_similarity: (state, action) => {
      let index_filter = action.payload.index_filter;
      let new_similarity = action.payload.similarity;
      state.list_filters[index_filter].similarity = new_similarity;
    },
    set_destination_add_manual_item_main_map: (state, action) => {
      state.destination_add_manual_item_main_map = action.payload.destination;
    },
    set_add_manual_item_main_map: (state, action) => {
      let destination = state.destination_add_manual_item_main_map;
      let new_item = action.payload.new_item;
      let target = state.list_mapper;

      for (let i = 0; i < destination.length; i++) {
        if (i === 0) {
          target = target[destination[i]].children;
        } else {
          target = target[destination[i]].subs;
        }
      }
      target.push(new_item);
      state.saveMapper = true;
    },
    unused_keyword: (state, action) => {
      if (state.keyword_cursor + 1 > state.keyword_list.length) {
        let cdxa, cdxb, kdata;
        let filtered_keyword_index =
          state.keyword_cursor - state.keyword_list.length;

        state.keyword_list_with_filter.forEach((itm, idxa) => {
          if (filtered_keyword_index > -1) {
            if (itm.length > 0) {
              itm.forEach((kwrdf, idxb) => {
                if (filtered_keyword_index === 0) {
                  if (state.keyword_list_with_filter[idxa][idxb + 1]) {
                    kdata = state.keyword_list_with_filter[idxa][idxb + 1];
                    cdxa = idxa;
                    cdxb = idxb + 1;
                  } else if (
                    state.keyword_list_with_filter[idxa + 1] &&
                    state.keyword_list_with_filter[idxa + 1].length > 0
                  ) {
                    kdata = state.keyword_list_with_filter[idxa + 1][0];
                    cdxa = idxa + 1;
                    cdxb = 0;
                  } else if (state.keyword_list_with_filter[idxa][idxb - 1]) {
                    kdata = state.keyword_list_with_filter[idxa][idxb - 1];
                    cdxa = idxa;
                    cdxb = idxb - 1;
                    state.keyword_cursor -= 1;
                  } else if (
                    state.keyword_list_with_filter[idxa - 1] &&
                    state.keyword_list_with_filter[idxa - 1].length > 0
                  ) {
                    kdata = state.keyword_list_with_filter[idxa - 1][0];
                    cdxa = idxa - 1;
                    cdxb = 0;
                    state.keyword_cursor -= 1;
                  } else {
                    state.keyword_cursor -= 1;
                    kdata = [];
                    cdxa = 0;
                    cdxb = 0;
                  }
                  let removed_item = state.keyword_list_with_filter[
                    idxa
                  ].splice(idxb, 1);
                  let itm = {
                    destination: idxa,
                    keyword_data: removed_item,
                  };
                  state.removed_keyword_list.push(itm);
                }
                filtered_keyword_index -= 1;
              });
            }
          }
        });
        let obj = {
          idxa: cdxa,
          idxb: cdxb,
          data: kdata,
        };
        // console.log(
        //   current(state).keyword_cursor - current(state).keyword_list.length
        // );
        state.filtered_keyword_to_show = obj;
        state.index_active_filter = cdxa;
      } else {
        // console.log(
        //   current(state).keyword_cursor - current(state).keyword_list.length
        // );
        let idx_keyword = state.keyword_cursor;
        // Checking next filtered keyword is exist
        let next_keyword_exist = false;
        let obj;
        let cdxa;
        state.keyword_list_with_filter.forEach((itm, idxx) => {
          if (!next_keyword_exist) {
            if (itm.length > 0) {
              next_keyword_exist = true;
              obj = {
                idxa: idxx,
                idxb: 0,
                data: itm[0],
              };
              cdxa = idxx;
            }
          }
        });
        if (next_keyword_exist) {
          state.filtered_keyword_to_show = obj;
          state.index_active_filter = cdxa;
        } else if (
          current(state).keyword_cursor - current(state).keyword_list.length ===
          -1
        ) {
          state.keyword_cursor -= 1;
        }
        let removed_item = state.keyword_list.splice(idx_keyword, 1);
        let itm = {
          destination: "Unfiltered",
          keyword_data: removed_item,
        };
        state.removed_keyword_list.push(itm);
      }
      // console.log(current(state).removed_keyword_list);
    },
    restore_unused_keyword: (state, action) => {
      const idx = action.payload.idx;
      const dst = action.payload.dst;
      const kdata = action.payload.kdata;
      state.removed_keyword_list.splice(idx, 1);

      if (dst === "Unfiltered") {
        state.keyword_list.push(kdata);
      } else {
        state.keyword_list_with_filter[dst].push(kdata);
      }
    },
    set_first_load_filtered_keyword_attemp: (state, action) => {
      let data = {
        idx_filter: action.payload.idx_filter,
        id_filter: action.payload.id_filter,
        filter_name: action.payload.filter_name,
      };

      state.first_load_filtered_keyword_attemp = data;
    },
    process_transfer_group_main_map: (state, action) => {
      let dest_path = action.payload.destination_address;
      let crnt_item_address = state.address_group_keyword_list_to_show;

      // Get target
      let target;
      crnt_item_address.forEach((itm, idx) => {
        let itmx = parseInt(itm);
        if (idx === 0) {
          target = state.list_mapper[itmx];
        } else if (idx === 1) {
          target = target.children[itmx];
        } else {
          target = target.subs[itmx];
        }
      });

      // Delete transfered item from old path
      let parnt;
      crnt_item_address.forEach((itm, idx) => {
        if (idx < crnt_item_address.length - 1) {
          if (idx === 0) {
            parnt = state.list_mapper[parseInt(itm)].children;
          } else {
            parnt = parnt[parseInt(itm)].subs;
          }
        } else {
          parnt.splice(parseInt(itm), 1);
        }
      });

      // Transfer target into new destination
      let dest_target;
      if (parseInt(dest_path[0]) === crnt_item_address[0]) {
        dest_path.forEach((itm, idx) => {
          if (idx === 0) {
            dest_target = state.list_mapper[parseInt(itm)].children;
          } else {
            if (
              idx === dest_path.length - 1 &&
              dest_path.length === crnt_item_address.length
            ) {
              if (
                parseInt(itm) > crnt_item_address[crnt_item_address.length - 1]
              ) {
                dest_target = dest_target[parseInt(itm) - 1].subs;
              } else {
                dest_target = dest_target[parseInt(itm)].subs;
              }
            } else {
              dest_target = dest_target[parseInt(itm)].subs;
            }
          }
        });
      } else {
        dest_path.forEach((itm, idx) => {
          if (idx === 0) {
            dest_target = state.list_mapper[parseInt(itm)].children;
          } else {
            dest_target = dest_target[parseInt(itm)].subs;
          }
        });
      }
      // Push target item into destination target
      dest_target.push(target);

      // Set save mapper true
      state.saveMapper = true;
    },
    process_merger_group_main_map: (state, action) => {
      let dest_path = action.payload.destination_address;
      let crnt_item_address = state.address_group_keyword_list_to_show;

      // Get target
      let target;
      crnt_item_address.forEach((itm, idx) => {
        let itmx = parseInt(itm);
        if (idx === 0) {
          target = state.list_mapper[itmx];
        } else if (idx === 1) {
          target = target.children[itmx];
        } else {
          target = target.subs[itmx];
        }
      });

      // Delete transfered item from old path
      let parnt;
      crnt_item_address.forEach((itm, idx) => {
        if (idx < crnt_item_address.length - 1) {
          if (idx === 0) {
            parnt = state.list_mapper[parseInt(itm)].children;
          } else {
            parnt = parnt[parseInt(itm)].subs;
          }
        } else {
          parnt.splice(parseInt(itm), 1);
        }
      });

      // Get target destination
      let dest_target;
      if (parseInt(dest_path[0]) === crnt_item_address[0]) {
        dest_path.forEach((itm, idx) => {
          if (idx === 0) {
            dest_target = state.list_mapper[parseInt(itm)].children;
          } else if (idx === dest_path.length - 1) {
            dest_target = dest_target[parseInt(itm)];
          } else {
            if (
              idx === dest_path.length - 1 &&
              dest_path.length === crnt_item_address.length
            ) {
              if (
                parseInt(itm) > crnt_item_address[crnt_item_address.length - 1]
              ) {
                dest_target = dest_target[parseInt(itm) - 1].subs;
              } else {
                dest_target = dest_target[parseInt(itm)].subs;
              }
            } else {
              dest_target = dest_target[parseInt(itm)].subs;
            }
          }
        });
      } else {
        dest_path.forEach((itm, idx) => {
          if (idx === 0) {
            dest_target = state.list_mapper[parseInt(itm)].children;
          } else if (idx === dest_path.length - 1) {
            dest_target = dest_target[parseInt(itm)];
          } else {
            dest_target = dest_target[parseInt(itm)].subs;
          }
        });
      }

      // Merger target keyword_list into target destination
      target.keyword_list.forEach((item) => {
        dest_target.keyword_list.push(item);
      });

      // Merger target subs into target destination
      target.subs.forEach((item) => {
        dest_target.subs.push(item);
      });

      // Set save mapper true
      state.saveMapper = true;
    },
    toggle_modal_image_viewer: (state, action) => {
      if (action.payload.status === undefined) {
        state.modal_image_viewer = !state.modal_image_viewer;
      } else {
        state.modal_image_viewer = action.payload.status;
      }
      let kwrd = action.payload.kwrd;
      if (kwrd) {
        state.image_dataset_modal_keyword = kwrd;
      } else {
        state.image_dataset_modal_keyword = "";
      }
    },
    correction_item: (state, action) => {
      let parent_address = action.payload.parent_address.split("-");
      let item_index = action.payload.item_index;
      let item_status = action.payload.status;

      let target;
      parent_address.forEach((item, idx) => {
        let idxxx = parseInt(item);
        if (idx === 0) {
          target = state.list_mapper[idxxx].children;
        } else if (idx !== 0 && idx < parent_address.length - 1) {
          target = target[idxxx].subs;
        } else {
          target = target[idxxx];
        }
      });

      if (item_status === "OK") {
        target.keyword_list[item_index].c = true;
      } else if (item_status === "ALL OK") {
        target.keyword_list.forEach((itm) => {
          itm.c = true;
        });
      } else {
        let parent_to_store_subs;
        let target_subs = target.subs;
        // Remove item form keyword_list
        target.keyword_list.splice(item_index, 1);

        if (target.keyword_list.length === 0) {
          // If the have subs, move to parent group
          parent_address.forEach((item, idx) => {
            let idxf = parseInt(item);
            if (idx < parent_address.length - 1) {
              if (idx === 0) {
                parent_to_store_subs = state.list_mapper[idxf].children;
              } else {
                parent_to_store_subs = parent_to_store_subs[idxf].subs;
              }
            }
          });
          let item_index_to_remove = parseInt(
            parent_address[parent_address.length - 1]
          );

          // If they have subs, place them to parent
          if (target_subs.length > 0) {
            let initial_index = item_index_to_remove + 1;
            target_subs.forEach((item) => {
              parent_to_store_subs.splice(initial_index, 0, item);
              initial_index++;
            });
          }

          // Remove item from parent
          parent_to_store_subs.splice(item_index_to_remove, 1);
        }
      }

      // Set save mapper
      state.saveMapper = true;
    },
    set_process_image_fetcher: (state, action) => {
      state.process_image_fethcer = action.payload.fetcher_status;
    },
    navigate_item_to_check: (state, action) => {
      let kwrd = action.payload.kwrd;
      if (kwrd) {
        state.image_dataset_modal_keyword = kwrd;
      } else {
        state.image_dataset_modal_keyword = "";
      }
    },
    toggle_show_modal_main_map_location_correction: (state, action) => {
      if (action.payload.status === undefined) {
        state.modal_main_map_location_correction =
          !state.modal_main_map_location_correction;
      } else {
        state.modal_main_map_location_correction = action.payload.status;
      }
    },
    set_keyword_highlight: (state, action) => {
      state.keyword_highlight = action.payload.kwrd_hghlight;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRun.pending, (state, action) => {});
    builder.addCase(fetchRun.rejected, (state, action) => {});
    builder.addCase(fetchRun.fulfilled, (state, action) => {
      state.run_list = action.payload;
    });
    builder.addCase(fetchKeywords.pending, (state, action) => {
      state.loading_keywords = true;
      // state.keyword_list_with_filter = false;
    });
    builder.addCase(fetchKeywords.rejected, (state, action) => {});
    builder.addCase(fetchKeywords.fulfilled, (state, action) => {
      // if (state.keyword_list.length > 0) {
      //   action.payload.data.forEach((obj) => {
      //     if (obj.id_run === state.selected_run) {
      //       // Check if the keyword already on list
      //       if (!state.keyword_list_string.includes(obj.keyword)) {
      //         state.keyword_list.push(obj);
      //         // Add obj.keyword into keyword_list_string to prevent duplicate
      //         state.keyword_list_string.push(obj.keyword);
      //       }
      //     }
      //   });
      //   state.loading_keywords = false;
      // } else {
      //   if (action.payload.data.length > 0) {
      //     // let obj = action.payload.data[0];
      //     // if (obj.id_run === state.selected_run) {
      //     //   state.keyword_list = action.payload.data;
      //     //   state.loading_keywords = false;
      //     // }
      //     action.payload.data.forEach((obj) => {
      //       if (obj.id_run === state.selected_run) {
      //         // Check if the keyword already on list
      //         if (!state.keyword_list_string.includes(obj.keyword)) {
      //           state.keyword_list.push(obj);
      //           // Add obj.keyword into keyword_list_string to prevent duplicate
      //           state.keyword_list_string.push(obj.keyword);
      //         }
      //       }
      //     });
      //   }
      // }

      if (action.payload.data.length > 0) {
        action.payload.data.forEach((obj) => {
          if (obj.id_run === state.selected_run) {
            // Check if the keyword already on list
            if (!state.keyword_list_string.includes(obj.keyword)) {
              state.keyword_list.push(obj);
              // Add obj.keyword into keyword_list_string to prevent duplicate
              state.keyword_list_string.push(obj.keyword);
            }
          }
        });
        state.loading_keywords = false;
      }

      if (state.keyword_list.length > 0 && action.payload.data.length === 0) {
        state.loading_keywords = false;
        // state.max_keyword = true;

        if (state.list_filters.length > 0) {
          // initiate first filtered keyword fetch
          // state.list_filters.forEach((fltr, idx) => {
          //   state.keyword_list_with_filter.push([]);
          //   if (
          //     state.index_filter_keyword_fetch === -1 &&
          //     state.active_filter === "0" &&
          //     fltr.priority !== 0
          //   ) {
          //     state.index_filter_keyword_fetch = idx;
          //     state.active_filter = fltr._id;
          //   }
          // });
        } else {
          state.max_keyword = true;
        }
      } else {
        // console.log("Unfiltered keyword list empty!");
        state.loading_keywords = false;
      }
      if (action.payload.data.length === 0) {
        state.finish_fetch_unfiltered = true;
        // See if there is filtered keyword to fetch or not
        // Fix misunderstand null and 0
        if (!state.list_filters[state.index_filter_keyword_fetch]) {
          // If there is no filtered keyword to fetch
          state.no_keyword_to_process = true;
        }
      }
    });
    builder.addCase(fetchKeywordsWithFilter.pending, (state, action) => {
      if (!state.keyword_list_with_filter) {
        state.keyword_list = [];
        state.keyword_cursor = 0;
      }
      state.loading_keywords = true;
    });
    builder.addCase(fetchKeywordsWithFilter.rejected, (state, action) => {
      // state.loading_keywords = false;
    });
    builder.addCase(fetchKeywordsWithFilter.fulfilled, (state, action) => {
      // let filter_name =
      //   state.list_filters[state.index_filter_keyword_fetch].filter_name;
      // state.keyword_list_with_filter = true;
      // if (!state.keyword_list_with_filter[filter_name]) {
      //   state.keyword_list_with_filter.push();
      // }
      // if (state.keyword_list.length > 0) {
      //   action.payload.data.forEach((obj) => {
      //     state.keyword_list.push(obj);
      //   });
      // } else {
      //   state.keyword_list = action.payload.data;
      // }
      if (action.payload.data.length > 0) {
        if (state.index_filter_keyword_fetch !== -1) {
          action.payload.data.forEach((obj) => {
            if (
              obj.id_run === state.selected_run &&
              !state.keyword_list_string.includes(obj.keyword)
            ) {
              state.keyword_list_with_filter[
                state.index_filter_keyword_fetch
              ].push(obj);
              // state.loading_keywords = false;

              // Add obj.keyword into keyword_list_string to prevent duplicate
              state.keyword_list_string.push(obj.keyword);
            }
          });
          state.loading_keywords = false;

          if (state.keyword_list.length === 0) {
            let obj = {
              idxa: state.index_filter_keyword_fetch,
              idxb: 0,
              data: state.keyword_list_with_filter[
                state.index_filter_keyword_fetch
              ][0],
            };
            if (!state.filtered_keyword_to_show.data) {
              state.filtered_keyword_to_show = obj;
              state.index_active_filter = 0;
            }
          }
        }
      } else {
        state.loading_keywords = false;
        if (state.index_filter_keyword_fetch + 1 < state.list_filters.length) {
          // check if next filter have existing keyword list to fetch
          while (true) {
            state.index_filter_keyword_fetch += 1;

            if (state.index_filter_keyword_fetch < state.list_filters.length) {
              if (
                state.list_filters[state.index_filter_keyword_fetch]
                  .list_keyword_exist === 1
              ) {
                state.active_filter =
                  state.list_filters[state.index_filter_keyword_fetch]._id;
                break;
              }
            } else {
              state.max_keyword = true;
              break;
            }
          }
          // state.index_filter_keyword_fetch += 1;
          // state.active_filter =
          //   state.list_filters[state.index_filter_keyword_fetch]._id;
        } else {
          state.max_keyword = true;
        }
      }
    });
    builder.addCase(fetchKeywordsNull.pending, (state, action) => {
      state.loading_keywords = true;
      state.keyword_list_with_filter = false;
    });
    builder.addCase(fetchKeywordsNull.rejected, (state, action) => {});
    builder.addCase(fetchKeywordsNull.fulfilled, (state, action) => {
      state.loading_keywords = false;
      if (state.keyword_list.length > 0) {
        action.payload.data.forEach((obj) => {
          state.keyword_list.push(obj);
        });
      } else {
        state.keyword_list = action.payload.data;
      }
    });
    builder.addCase(fetchMapper.pending, (state, action) => {
      // state.loading_keywords = true;
      state.loadingMapper = true;
    });
    builder.addCase(fetchMapper.rejected, (state, action) => {});
    builder.addCase(fetchMapper.fulfilled, (state, action) => {
      // state.loading_keywords = false;
      // if (state.keyword_list.length > 0) {
      //   action.payload.data.forEach((obj) => {
      //     state.keyword_list.push(obj);
      //   });
      // } else {
      //   state.keyword_list = action.payload.data;
      // }
      state.list_mapper = action.payload.data;
      state.loadingMapper = false;
    });
    builder.addCase(
      fetchDatasetKeywordsInformation.pending,
      (state, action) => {
        // state.loading_keywords = true;
      }
    );
    builder.addCase(
      fetchDatasetKeywordsInformation.rejected,
      (state, action) => {}
    );
    builder.addCase(
      fetchDatasetKeywordsInformation.fulfilled,
      (state, action) => {
        state.total_keyword_of_dataset = action.payload.data.data.totalKeyword;
        state.total_keyword_processed_of_dataset =
          action.payload.data.data.processedKeyword;
      }
    );
    builder.addCase(registerFilter.pending, (state, action) => {
      state.register_filter = true;
    });
    builder.addCase(registerFilter.rejected, (state, action) => {
      state.register_filter = true;
    });
    builder.addCase(registerFilter.fulfilled, (state, action) => {
      state.load_new_filter = true;
      state.register_filter = false;
      if (action.payload.data.typeFilter === 1) {
        state.list_filters.push(action.payload.data.processed_data);
      }
    });
    builder.addCase(getFilters.pending, (state, action) => {
      state.load_new_filter = true;
      state.list_filter_loaded = false;
    });
    builder.addCase(getFilters.rejected, (state, action) => {});
    builder.addCase(getFilters.fulfilled, (state, action) => {
      state.list_filters = action.payload.filter_list;
      let bwex = [];

      if (state.list_filters.length > 0) {
        // Fix list_existing when there are block words inside filter list
        state.list_filters.forEach((itm) => {
          itm.priority === 0 && bwex.push(0);
        });
        let fixed_list_existing = bwex.concat(action.payload.list_existing);

        state.list_filters.forEach((fltr, idx) => {
          state.keyword_list_with_filter.push([]);
          if (
            state.index_filter_keyword_fetch === -1 &&
            state.active_filter === "0" &&
            fltr.priority !== 0
          ) {
            state.index_filter_keyword_fetch = idx;
            state.active_filter = fltr._id;
          }
          if (fixed_list_existing[idx] === undefined) {
            state.list_filters[idx].list_keyword_exist = 1;
          } else {
            state.list_filters[idx].list_keyword_exist =
              fixed_list_existing[idx];
          }
        });
      }

      state.load_new_filter = false;
      state.list_filter_loaded = true;
      let idx_fltr = action.payload.next_filter_to_load.index + bwex.length;
      state.index_filter_keyword_fetch = idx_fltr;
      state.first_load_filtered_keyword_attemp = {
        idx_filter: idx_fltr,
      };
    });
    builder.addCase(deleteFilter.pending, (state, action) => {});
    builder.addCase(deleteFilter.rejected, (state, action) => {});
    builder.addCase(deleteFilter.fulfilled, (state, action) => {
      state.list_filters = state.list_filters.filter((e) => {
        return e._id !== action.payload.deleted_id;
      });
    });
    builder.addCase(updateKeywordMapAddress.pending, (state, action) => {});
    builder.addCase(updateKeywordMapAddress.rejected, (state, action) => {});
    builder.addCase(updateKeywordMapAddress.fulfilled, (state, action) => {
      // console.log("Keyword map address updated!");
    });
    builder.addCase(mapperUpdate.pending, (state, action) => {
      state.savingMapper = true;
    });
    builder.addCase(mapperUpdate.rejected, (state, action) => {});
    builder.addCase(mapperUpdate.fulfilled, (state, action) => {
      state.saveMapper = false;
      state.savingMapper = false;
    });
    builder.addCase(fetchImageDataset.pending, (state, action) => {
      state.loading_image_dataset = true;
    });
    builder.addCase(fetchImageDataset.rejected, (state, action) => {
      state.loading_image_dataset = false;
    });
    builder.addCase(fetchImageDataset.fulfilled, (state, action) => {
      state.loading_image_dataset = false;
      let image_data = action.payload.data.image_data;
      let kwrd = action.payload.data_info.keyword;
      let dtp = {
        keyword: kwrd,
        image_data: image_data,
      };
      state.image_dataset_modal.push(dtp);
    });
  },
});

export const {
  get_run_list,
  change_run,
  nav_cursor,
  insert_group_to_map,
  append_to_group,
  create_sub,
  set_index_active_filter,
  reset_keyword_cursor,
  set_group_keyword_list_to_show,
  remove_keyword_from_map,
  update_filter_similarity,
  set_destination_add_manual_item_main_map,
  set_add_manual_item_main_map,
  unused_keyword,
  restore_unused_keyword,
  set_first_load_filtered_keyword_attemp,
  process_transfer_group_main_map,
  process_merger_group_main_map,
  toggle_modal_image_viewer,
  correction_item,
  set_process_image_fetcher,
  navigate_item_to_check,
  toggle_show_modal_main_map_location_correction,
  set_keyword_highlight,
} = keywordSlice.actions;
export default keywordSlice.reducer;
