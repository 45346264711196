import { Modal, Button, Form } from "react-bootstrap";
import { BoxArrowRight, Trash, Google } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  remove_keyword_from_map,
  updateKeywordMapAddress,
  process_transfer_group_main_map,
  process_merger_group_main_map,
  toggle_show_modal_main_map_location_correction,
  toggle_modal_image_viewer,
} from "../features/keywords/keywordSlice";
import { useState, useCallback } from "react";
import TransferChildrenDestination from "./TransferChildrenDestination";

const ModalGroupKeywordList = ({ modalState, fnToggler, klist }) => {
  const dispatch = useDispatch();
  const idRun = useSelector((state) => state.keywords.selected_run);
  const run_list = useSelector((state) => state.keywords.run_list);
  const groupKeywordListToShow = useSelector(
    (state) => state.keywords.group_keyword_list_to_show
  );
  const addressKeywordListToShow = useSelector(
    (state) => state.keywords.address_group_keyword_list_to_show
  );
  const listMapper = useSelector((state) => state.keywords.list_mapper);
  const [modeTransfer, setModeTransfer] = useState(false);
  const [canTransfer, setCanTransfer] = useState(false);
  const [canMerge, setCanMerge] = useState(false);
  const [transferDestination, setTransferDestination] = useState([]);
  const [destPath, setDestPath] = useState([]);
  const [destString, setDestString] = useState([]);

  // Force re-rendering component
  const [state, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const fnModeTransfer = () => {
    setModeTransfer(!modeTransfer);
    setTransferDestination([]);
    setCanTransfer(false);
    setCanMerge(false);
  };

  const setTargetMapAddress = (idx_itm) => {
    dispatch(
      remove_keyword_from_map({
        item_index: idx_itm,
        keyword_target: groupKeywordListToShow[idx_itm].k,
      })
    );
    let post_data = {
      id_run: idRun,
      keyword: groupKeywordListToShow[idx_itm].k,
      map_address: [],
    };
    if (groupKeywordListToShow[idx_itm].v > 0) {
      dispatch(updateKeywordMapAddress(post_data));
    }
    if (groupKeywordListToShow.length - 1 === 0) {
      fToggler();
    }
  };

  const buildDestination = (el) => {
    let rdst, dstp, dsts;
    let slct_name = el.target.name;
    let itm_idx = el.target.value;

    if (slct_name.includes("root")) {
      rdst = [];
      dstp = [];
      dsts = [];
      if (itm_idx !== "-1") {
        let ritm = listMapper[itm_idx];
        rdst.push(ritm);
        dstp.push(itm_idx);
        dsts.push(ritm.map_name);
      }
    } else {
      rdst = transferDestination;
      dstp = destPath;
      dsts = destString;
      // let dummy = { test: "OK" };
      // rdst.push(dummy);
      let idx_subs = parseInt(slct_name.split("-")[2]);
      rdst = rdst.slice(0, idx_subs + 1);
      dstp = dstp.slice(0, idx_subs + 1);
      dsts = dsts.slice(0, idx_subs + 1);
      if (itm_idx !== "-1") {
        let itm_to_push;
        if (idx_subs === 0) {
          itm_to_push = rdst[idx_subs].children[itm_idx];
        } else {
          itm_to_push = rdst[idx_subs].subs[itm_idx];
        }
        rdst.push(itm_to_push);
        dstp.push(itm_idx);
        dsts.push(itm_to_push.keyword_list[0].k);
      }
    }
    // Check is it can transfer
    if (rdst.length > 0) {
      // Check is destination not containing current group
      dsts.includes(groupKeywordListToShow[0].k)
        ? setCanTransfer(false)
        : setCanTransfer(true);

      // Check is it can be merge
      setCanMerge(false);
      if (rdst.length > 1) {
        dsts.includes(groupKeywordListToShow[0].k)
          ? setCanMerge(false)
          : setCanMerge(true);
      }
    } else {
      setCanTransfer(false);
      setCanMerge(false);
    }

    setTransferDestination(rdst);
    setDestPath(dstp);
    setDestString(dsts);
    forceUpdate();
  };

  const searchOnGogle = (kwrd) => {
    let datasetName;
    run_list.map((itm) => {
      if (itm._id === idRun) {
        datasetName = itm.name.split(" ");
      }
    });
    let g_url = "https://www.google.com/search?q=";
    let country_code = datasetName.pop().toLowerCase();
    if (country_code.length > 2 || country_code === "us") {
      g_url = g_url + encodeURIComponent(kwrd) + "&hl=en&gl=us";
    } else {
      g_url =
        g_url +
        encodeURIComponent(kwrd) +
        "&hl=" +
        country_code +
        "&gl=" +
        country_code;
    }
    window.open(g_url, "_blank");
  };

  const fToggler = () => {
    fnToggler();
    setModeTransfer(false);
    setTransferDestination([]);
    setDestPath([]);
    setDestString([]);
    setCanTransfer(false);
    setCanMerge(false);
  };

  const processTransfer = () => {
    dispatch(
      process_transfer_group_main_map({ destination_address: destPath })
    );
    fToggler();
    // Close modal main map and image
    dispatch(toggle_show_modal_main_map_location_correction({ status: false }));
    dispatch(toggle_modal_image_viewer({ status: false, kwrd: "" }));
  };

  const processMerger = () => {
    dispatch(process_merger_group_main_map({ destination_address: destPath }));
    fToggler();
    // Close modal main map and image
    dispatch(toggle_show_modal_main_map_location_correction({ status: false }));
    dispatch(toggle_modal_image_viewer({ status: false, kwrd: "" }));
  };

  return (
    <Modal
      show={modalState}
      animation={false}>
      <Modal.Header className='py-2 px-4 c_modal_header'>
        <Modal.Title>
          {modeTransfer ? "Transfer" : "List"} Keyword Group
        </Modal.Title>
        {!modeTransfer && (
          <BoxArrowRight
            className='c_pointer text-success'
            style={{ fontSize: "20px" }}
            onClick={() => {
              fnModeTransfer();
            }}></BoxArrowRight>
        )}
      </Modal.Header>
      <Modal.Body>
        {!modeTransfer ? (
          <ul>
            {groupKeywordListToShow.map((itm, i) => {
              return (
                <li key={i}>
                  <span
                    className='me-2'
                    onClick={() => {
                      searchOnGogle(itm.k);
                    }}>
                    <Google
                      className='c_pointer text-success'
                      style={{ fontSize: "14px" }}></Google>
                  </span>
                  <span className='c_pointer'>
                    {itm.k} - {itm.v}
                  </span>
                  <Trash
                    className='text-danger c_pointer'
                    style={{ fontSize: "16px", marginLeft: "5px" }}
                    onClick={() => {
                      setTargetMapAddress(i);
                    }}
                  />
                </li>
              );
            })}
          </ul>
        ) : (
          <>
            <div className='ms-2 small alert alert-info p-2'>
              Selected Group :{" "}
              <strong>" {groupKeywordListToShow[0].k} "</strong>
            </div>
            <hr></hr>
            <p className='ms-2 mb-0 small'>Select destination :</p>
            <div className='p-2'>
              <Form.Select
                size='sm'
                name='target-root-selector'
                onChange={(e) => {
                  buildDestination(e);
                }}>
                <option value='-1'>None</option>
                {listMapper.map((obj, idx) => {
                  return (
                    <option
                      key={idx}
                      value={idx}>
                      {obj.map_name}
                    </option>
                  );
                })}
              </Form.Select>
              {transferDestination.length > 0 && (
                <div>
                  {transferDestination.map((itm, idx) => {
                    return (
                      <div key={idx}>
                        {idx === 0 ? (
                          <Form.Select
                            value={destPath[idx + 1] ? destPath[idx + 1] : "-1"}
                            className='mt-1'
                            size='sm'
                            name={"target-sub-" + idx + "-selector"}
                            onChange={(e) => {
                              buildDestination(e);
                            }}>
                            <option value='-1'>------</option>
                            {itm.children.map((c, idxc) => {
                              return (
                                <option
                                  key={idxc}
                                  value={idxc}>
                                  {c.keyword_list[0].k}
                                </option>
                              );
                            })}
                          </Form.Select>
                        ) : (
                          <Form.Select
                            value={destPath[idx + 1] ? destPath[idx + 1] : "-1"}
                            className='mt-1'
                            size='sm'
                            name={"target-sub-" + idx + "-selector"}
                            onChange={(e) => {
                              buildDestination(e);
                            }}>
                            <option value='-1'>------</option>
                            {itm.subs.map((sbs, idxsbs) => {
                              return (
                                <option
                                  key={idxsbs}
                                  value={idxsbs}>
                                  {sbs.keyword_list[0].k}
                                </option>
                              );
                            })}
                          </Form.Select>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {modeTransfer ? (
          <>
            <Button
              variant='danger'
              className='btn btn-sm'
              onClick={() => fnModeTransfer()}>
              Cancel
            </Button>
            {canMerge ? (
              <Button
                variant='warning'
                className='btn btn-sm'
                onClick={() => processMerger()}>
                Merge
              </Button>
            ) : (
              <Button
                variant='secondary'
                className='btn btn-sm disabled'>
                Merge
              </Button>
            )}
            {canTransfer ? (
              <Button
                variant='success'
                className='btn btn-sm'
                onClick={() => processTransfer()}>
                Transfer
              </Button>
            ) : (
              <Button
                variant='secondary'
                className='btn btn-sm disabled'>
                Transfer
              </Button>
            )}
          </>
        ) : (
          <Button
            variant='danger'
            className='btn btn-sm'
            onClick={() => fToggler()}>
            Close
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalGroupKeywordList;
