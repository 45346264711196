import React, { useState } from "react";
import { Modal, Button, Form, Accordion } from "react-bootstrap";
import { Search, Trash3Fill, XSquare } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { deleteFilter } from "../features/keywords/keywordSlice";

const ModalManageFilter = ({ manageModalState, fnManageToggler }) => {
  const dispatch = useDispatch();
  const presetFilters = useSelector((state) => state.keywords.preset_filters);
  const loadingFilters = useSelector((state) => state.keywords.load_new_filter);
  const listFilters = useSelector((state) => state.keywords.list_filters);
  const [listToShow, setListToShow] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [selectedPriority, setSelectedPriority] = useState(-1);

  const showListFilter = (e) => {
    let filteredList = listFilters.filter((itm) => {
      return itm.priority === parseInt(e.target.value);
    });
    setListToShow(filteredList);
    setSelectedPriority(e.target.value);
  };

  const delFilter = (id, keyword) => {
    dispatch(deleteFilter({ id_filter: id }));
    let updt = listToShow.filter((itm) => {
      return itm._id !== id;
    });
    setListToShow(updt);
  };

  const toggleSearchBox = () => {
    setShowSearchBox(!showSearchBox);
    if (showSearchBox) {
      let filteredList = listFilters.filter((itm) => {
        return itm.priority === parseInt(selectedPriority);
      });
      setListToShow(filteredList);
    } else {
      setListToShow([]);
    }
  };

  const searchFilter = (e) => {
    let text = e.target.value;
    if (text) {
      let filteredList = listFilters.filter((itm) => {
        return itm.filter_name.toLowerCase().includes(text);
      });
      setListToShow(filteredList);
    } else {
      setListToShow([]);
    }
  };

  const fToggler = () => {
    setListToShow([]);
    // let filteredList = listFilters.filter((itm) => {
    //   return itm.priority === parseInt(selectedPriority);
    // });
    // setListToShow(filteredList);

    setShowSearchBox(false);
    setSelectedPriority(-1);
    fnManageToggler();
  };

  return (
    <Modal
      show={manageModalState}
      animation={false}>
      <Modal.Header className='py-2 px-4 c_modal_header'>
        <Modal.Title>
          <span>Manage Filter</span>
        </Modal.Title>
        {!showSearchBox && (
          <button
            className='btn btn-sm text-primary'
            onClick={() => {
              toggleSearchBox();
            }}>
            <Search style={{ fontSize: "22px" }} />
          </button>
        )}
      </Modal.Header>
      <Modal.Body>
        {!showSearchBox ? (
          <div className='d-flex align-items-center'>
            <p className='text-muted mb-1 text-nowrap me-3'>
              Select pre-set filter :
            </p>
            <Form.Select
              onChange={(e) => {
                showListFilter(e);
              }}
              defaultValue={selectedPriority}
              size='sm'
              className='mb-2'>
              <option value='-1'>None</option>
              {presetFilters.map((itm, i) => {
                if (itm !== "=== Pre-made ===") {
                  return (
                    <option
                      value={i}
                      key={i}>
                      {itm}
                    </option>
                  );
                } else {
                  return null;
                }
              })}
            </Form.Select>{" "}
          </div>
        ) : (
          // <span>Search box</span>
          <div className='d-flex'>
            {/* <input
              className='form-control'
              name='input-search-filter'
              placeholder='type here to search filter'
              onChange={(e) => {
                searchFilter(e);
              }}></input> */}
            <Form.Control
              placeholder='type here to search filter'
              onChange={(e) => {
                searchFilter(e);
              }}
              autoFocus={true}
            />
            <button
              className='btn btn-sm ms-3 p-0'
              onClick={() => {
                toggleSearchBox();
              }}>
              <XSquare
                className='text-danger'
                style={{ fontSize: "25px" }}
              />{" "}
            </button>
          </div>
        )}
        <hr />
        {loadingFilters && (
          <span className='text-muted small'>Loading filters ...</span>
        )}
        {listToShow.length === 0 && (
          <p className='text-center text-muted small'>
            ===== Empty list filter =====
          </p>
        )}
        {listToShow.length > 0 &&
          listToShow.map((obj, i) => {
            return (
              // <div
              //   className='d-flex c_hover'
              //   key={i}>
              //   <p className='mb-0 ms-2'>{obj.filter_name}</p>
              //   <button
              //     className='ms-2 btn btn-sm p-0'
              //     onClick={() => {
              //       delFilter(obj._id, obj.filter_name);
              //     }}>
              //     <Trash3Fill className='b_icon_trash_small' />
              //   </button>
              // </div>
              <Accordion key={i}>
                <Accordion.Item eventKey={i}>
                  <Accordion.Header>
                    <p className='mb-0 ms-2'>{obj.filter_name}</p>
                    <button
                      className='ms-2 btn btn-sm p-0'
                      onClick={() => {
                        delFilter(obj._id, obj.filter_name);
                      }}>
                      <Trash3Fill className='b_icon_trash_small' />
                    </button>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul
                      className='mb-1 mt-1'
                      style={{ fontSize: "14px" }}>
                      {obj.similarity.map((itm, i) => {
                        return <li key={i}>{itm}</li>;
                      })}
                    </ul>
                    {obj.similarity.length === 0 && (
                      <span
                        className='text-muted c_center my-2'
                        style={{ fontSize: "12px" }}>
                        ====== No Similar ======
                      </span>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            );
          })}
        {/* <Accordion>
          <Accordion.Item eventKey='0'>
            <Accordion.Header>Accordion Item #1</Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='1'>
            <Accordion.Header>Accordion Item #2</Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion> */}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='danger'
          className='btn btn-sm'
          onClick={() => fToggler()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalManageFilter;
