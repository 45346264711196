import { useDispatch, useSelector } from "react-redux";
import {
  fetchRun,
  fetchKeywords,
  fetchKeywordsNull,
  change_run,
  fetchMapper,
  set_index_active_filter,
  getFilters,
  reset_keyword_cursor,
  mapperUpdate,
  fetchKeywordsWithFilter,
  fetchDatasetKeywordsInformation,
} from "../features/keywords/keywordSlice";
import { useEffect, useState } from "react";
import { CloudArrowUp, Images, PencilSquare } from "react-bootstrap-icons";
import ModalSetFilterPriority from "./ModalSetFilterPriority";
import { Link, useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const Navbar = () => {
  const dispatch = useDispatch();
  const [changeFilterModalShow, setChangeFilterModalShow] = useState(false);
  const run_list = useSelector((state) => state.keywords.run_list);
  const saveMapper = useSelector((state) => state.keywords.saveMapper);
  const listMapper = useSelector((state) => state.keywords.list_mapper);
  const listFilter = useSelector((state) => state.keywords.list_filters);
  const id_run = useSelector((state) => state.keywords.selected_run);
  const processImageFetcher = useSelector(
    (state) => state.keywords.process_image_fethcer
  );
  const locs = useLocation().pathname;
  const args_id = useLocation().search.substring(1);

  const saveMap = () => {
    dispatch(mapperUpdate(listMapper));
  };

  const changeActiveFilter = (e) => {
    dispatch(set_index_active_filter({ value: e.target.value }));
    // let post_data = {
    //   id_run: id_run,
    //   offset: 0,
    //   id_filter: activeFilter,
    // };
    // console.log(post_data);
    // dispatch(fetchKeywordsWithFilter(post_data));

    // if (e.target.value !== "0") {
    //   let post_data = {
    //     id_run: id_run,
    //     offset: 0,
    //     id_filter: e.target.value,
    //   };
    //   dispatch(fetchKeywordsWithFilter(post_data));
    // } else {
    //   dispatch(fetchKeywords({ _id: id_run, offset: 0 }));
    // }
  };

  const toggelChangeFilterModal = () => {
    setChangeFilterModalShow(!changeFilterModalShow);
  };

  useEffect(() => {
    if (run_list.length === 0) {
      dispatch(fetchRun());
    }
  }, [dispatch]);

  const changeRun = (e) => {
    dispatch(change_run({ _id: e }));

    if (e === "-1") {
      dispatch(fetchKeywordsNull({ offset: 0 }));
    } else {
      if (e !== "0") {
        // dispatch(reset_keyword_cursor());
        // dispatch(fetchKeywords({ _id: e, offset: 0 }));
        // dispatch(fetchMapper({ _id: e }));
        if (locs === "/") {
          // dispatch(getFilters(e));
        } else if (locs === "/correction") {
          dispatch(fetchMapper({ _id: e }));
        } else {
          dispatch(getFilters(e));
        }
        // dispatch(fetchDatasetKeywordsInformation({ _id: e }));
      }
    }
  };

  return (
    <div className='c_navbar'>
      <div className='c_nav_l'>
        <div className='d-flex justify-content-center align-items-center me-3'>
          <div className='text-nowrap me-3'>Dataset :</div>
          <select
            defaultValue={0}
            className='form-select form-select-sm'
            onChange={(e) => {
              changeRun(e.target.value);
            }}>
            <option value='0'>None</option>
            {/* <option value='-1'>Null</option> */}
            {/* Test : 65952d1954001624ef04e491*/}
            {run_list.map(function (object, i) {
              if (
                object.name !== "Test Douxreveurs" &&
                object._id === args_id
              ) {
                return (
                  <option
                    value={object._id}
                    key={i}>
                    {object.name}
                  </option>
                );
              }
            })}
          </select>
        </div>
        {id_run !== 0 && locs === "/" && (
          <div className='d-flex justify-content-center align-items-center'>
            <div className='text-nowrap me-3'>Filter :</div>
            <select
              defaultValue={0}
              className='form-select form-select-sm'
              aria-label='Default select example'
              onChange={(e) => changeActiveFilter(e)}>
              <option value={-1}>Unfiltered</option>
              {listFilter.map(function (object, i) {
                return (
                  <option
                    value={i}
                    key={i}>
                    P{object.priority} - {object.filter_name}
                  </option>
                );
              })}
              {/* <option value='1'>=== Pre-made ===</option>
            <option value='2'>Priority</option> */}
            </select>
          </div>
        )}
      </div>
      <div className='c_nav_r d-flex'>
        {saveMapper && (
          <button
            className='btn btn-sm btn-warning'
            onClick={() => {
              saveMap();
            }}>
            <CloudArrowUp
              style={{ fontSize: "18px" }}
              className='me-2 mb-0'
            />
            Save Map
          </button>
        )}
        {processImageFetcher && (
          <div className='image_process_inidcator ms-1'>
            <Spinner className='image_process_inidcator_circle' />
            <Images className='image_process_inidcator_img' />
          </div>
        )}
        {/* {locs === "/" && (
          <Link to={"/correction"}>
            <button className='btn btn-sm btn-warning'>
              <PencilSquare
                style={{ fontSize: "18px" }}
                className='me-2 mb-0'
              />
              Correction
            </button>
          </Link>
        )} */}
      </div>
      <ModalSetFilterPriority
        modalState={changeFilterModalShow}
        fnHandleModal={toggelChangeFilterModal}
      />
    </div>
  );
};

export default Navbar;
