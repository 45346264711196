import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { set_add_manual_item_main_map } from "../features/keywords/keywordSlice";

const ModalAddManualMainMapItems = ({ modalState, fnToggler }) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");

  const submitItemMainMap = () => {
    let inptStr = "Hand: " + inputValue;
    const k_list = {
      k: inptStr,
      v: 0,
    };
    const new_item = {
      keyword_list: [k_list],
      subs: [],
    };
    dispatch(set_add_manual_item_main_map({ new_item: new_item }));
    setInputValue("");
    fToggler();
  };

  const fToggler = () => {
    fnToggler();
  };
  return (
    <Modal
      show={modalState}
      animation={false}>
      <Modal.Header className='py-2 px-4 c_modal_header'>
        <Modal.Title>Add Item to Main Map</Modal.Title>
      </Modal.Header>{" "}
      <Modal.Body>
        <Form.Group
          className='mb-2'
          controlId='exampleForm.ControlInput1'
          autoFocus>
          <Form.Control
            autoFocus={true}
            type='text'
            placeholder='type here ...'
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='danger'
          className='btn btn-sm'
          onClick={() => fToggler()}>
          Cancel
        </Button>
        <Button
          variant='success'
          className='btn btn-sm'
          onClick={() => submitItemMainMap()}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAddManualMainMapItems;
