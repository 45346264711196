import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilters,
  change_run,
  reset_keyword_cursor,
  fetchKeywords,
  fetchMapper,
  unused_keyword,
  updateKeywordMapAddress,
} from "../features/keywords/keywordSlice";
import { ArrowCounterclockwise } from "react-bootstrap-icons";

const ButtonBox = ({
  objKeyword,
  fnToggle,
  fnToggleManage,
  fnModalRemovedKeyword,
}) => {
  const dispatch = useDispatch();
  const idRun = useSelector((state) => state.keywords.selected_run);
  const loadingFilter = useSelector((state) => state.keywords.load_new_filter);
  const kList = useSelector((state) => state.keywords.keyword_list);
  const klLength = useSelector((state) => state.keywords.keyword_list.length);
  const kCursor = useSelector((state) => state.keywords.keyword_cursor);

  const applyFilter = () => {
    // let filter_list = [];
    // listFilter.forEach((obj) => {
    //   filter_list.push(obj.filter_name);
    //   if (obj.similarity.length > 0) {
    //     obj.similarity.forEach((sml) => {
    //       filter_list.push(sml);
    //     });
    //   }
    // });
    // let post_data = {
    //   id_run: idRun,
    //   offset: 0,
    //   filter_list: filter_list,
    // };
    // dispatch(fetchKeywordsWithFilter(post_data));
    dispatch(change_run({ _id: idRun }));
    dispatch(reset_keyword_cursor());
    dispatch(fetchKeywords({ _id: idRun, offset: 0 }));
    dispatch(fetchMapper({ _id: idRun }));
    dispatch(getFilters(idRun));
  };

  const getOffset = () => {
    let offset = 0;
    kList.forEach((obj) => {
      if (!obj.map_address || obj.map_address.length === 0) {
        offset++;
      }
    });
    return offset;
  };

  const unusedKeyword = () => {
    // let rOffset = getOffset();
    let post_data = {
      id_keyword: objKeyword._id,
      map_address: ["Unused"],
    };
    dispatch(updateKeywordMapAddress(post_data));

    dispatch(unused_keyword());
  };

  const popRemovedKeyword = () => {
    fnModalRemovedKeyword();
  };

  return (
    <div className='button-box'>
      <button
        className='btn btn-outline-success btn-sm'
        onClick={() => fnToggle()}>
        Add Filter
      </button>
      <button
        className='btn btn-outline-success ms-2 btn-sm'
        onClick={() => {
          if (loadingFilter) {
            dispatch(getFilters(idRun));
          }
          fnToggleManage();
        }}>
        Manage Filter
      </button>
      <button
        className='btn btn-primary ms-2 btn-sm'
        onClick={() => {
          applyFilter();
        }}>
        Apply
      </button>
      <>
        <span className='mx-3 text-muted'>|</span>
        <button
          className='btn btn-outline-danger btn-sm'
          onClick={() => {
            unusedKeyword();
          }}>
          Remove
        </button>
        <button
          className='btn btn-outline-secondary btn-sm ms-2'
          onClick={() => {
            popRemovedKeyword();
          }}>
          <ArrowCounterclockwise />
        </button>
      </>
    </div>
  );
};

export default ButtonBox;
