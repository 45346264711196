import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchKeywordsWithFilter,
  set_first_load_filtered_keyword_attemp,
  fetchKeywords,
  reset_keyword_cursor,
  fetchMapper,
  fetchDatasetKeywordsInformation,
} from "../features/keywords/keywordSlice";

const EmptyFilteredKeywordList = ({ filterList }) => {
  const dispatch = useDispatch();
  const [countdown, setCountdown] = useState(3);
  const firstLoadFilteredKeywordAttemp = useSelector(
    (state) => state.keywords.first_load_filtered_keyword_attemp
  );
  const idRun = useSelector((state) => state.keywords.selected_run);
  const listMapper = useSelector((state) => state.keywords.list_mapper);
  const no_keyword_to_process = useSelector(
    (state) => state.keywords.no_keyword_to_process
  );

  const fetchFilteredKeyword = () => {
    if (countdown > 0) {
      setCountdown(countdown - 1);
    } else {
      if (listMapper.length === 0) {
        let e = idRun;
        dispatch(reset_keyword_cursor());
        dispatch(fetchKeywords({ _id: e, offset: 0 }));
        dispatch(fetchMapper({ _id: e }));
        dispatch(fetchDatasetKeywordsInformation({ _id: e }));
      } else {
        if (!no_keyword_to_process) {
          let index_filter = !firstLoadFilteredKeywordAttemp
            ? 0
            : firstLoadFilteredKeywordAttemp.idx_filter;
          let post_data = {
            id_run: filterList[index_filter].id_run,
            offset: 0,
            id_filter: filterList[index_filter]._id,
          };
          // let flfka = {
          //   idx_filter: index_filter,
          //   id_filter: filterList[index_filter]._id,
          //   filter_name: filterList[index_filter].filter_name,
          // };
          // dispatch(set_first_load_filtered_keyword_attemp(flfka));
          dispatch(fetchKeywordsWithFilter(post_data));
        }
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchFilteredKeyword();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [fetchFilteredKeyword]);

  const renderElement = () => {
    if (listMapper.length === 0) {
      return (
        <div className='alert alert-warning text-center p-2 m-3'>
          Fetching keyword in {countdown}
        </div>
      );
    } else {
      return (
        <div className='alert alert-warning text-center p-2 m-3'>
          Unfiltered keyword list empty! Fetching filtered keyword in{" "}
          {countdown}
        </div>
      );
    }
  };
  return <>{renderElement()}</>;
};

export default EmptyFilteredKeywordList;
