import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  registerFilter,
  update_filter_similarity,
} from "../features/keywords/keywordSlice";
import { Search } from "react-bootstrap-icons";

const ModalFilter = ({ modalState, fnToggler, keyword }) => {
  const dispatch = useDispatch();
  // const txt_array = keyword.keyword.split(" ");
  // let txt_array = [];
  let txt_array;
  let objKwrdFix;
  const [selectedPriority, setSelectedPriority] = useState(-1);
  const [destinationExistingFilter, setDestinationExistingFilter] =
    useState(-1);
  const [inputFilterValue, setInputFilterValue] = useState("");
  const [searchExistingFilter, setSearchExistingFilter] = useState(false);
  const [listFilterToShow, setListFilterToShow] = useState([]);
  const [inputCheckValueText, setInputCheckValueText] = useState("");
  const [checkFilterValue, setCheckFilterValue] = useState({
    checkedList: [],
  });
  const presetFilters = useSelector((state) => state.keywords.preset_filters);
  const existFilters = useSelector((state) => state.keywords.list_filters);
  const runID = useSelector((state) => state.keywords.selected_run);

  const buildFilter = () => {
    let newFilter;
    if (inputFilterValue) {
      newFilter = inputFilterValue;
    } else {
      if (inputCheckValueText) {
        newFilter = inputCheckValueText;
      } else {
        console.log("Nothing to register");
      }
    }
    if (newFilter) {
      let data_post = {
        id_run: runID,
        filter_name: newFilter,
        priority: selectedPriority,
        similarity: [],
      };
      if (destinationExistingFilter >= 0) {
        data_post._id = existFilters[destinationExistingFilter]._id;
        data_post.filter_name =
          existFilters[destinationExistingFilter].filter_name;
        data_post.priority = existFilters[destinationExistingFilter].priority;
        // copy similarity from selected existing filter
        for (
          let x = 0;
          x < existFilters[destinationExistingFilter].similarity.length;
          x++
        ) {
          const e = existFilters[destinationExistingFilter].similarity[x];
          data_post.similarity.push(e);
        }
        // Put new filter to similarity data_post
        data_post.similarity.push(newFilter);

        // Upadate state
        dispatch(
          update_filter_similarity({
            index_filter: destinationExistingFilter,
            similarity: data_post.similarity,
          })
        );
      }

      dispatch(registerFilter(data_post));
      fToggler();
    }
  };

  const appendToInputFilter = (e) => {
    const { value, checked } = e.target;
    const { checkedList } = checkFilterValue;
    let temp = checkFilterValue.checkedList;
    if (checked) {
      setCheckFilterValue({
        checkedList: [...checkedList, parseInt(value)],
      });
      temp.push(parseInt(value));
    } else {
      setCheckFilterValue({
        checkedList: checkedList.filter((e) => e !== parseInt(value)),
      });
      temp = temp.filter((e) => e !== parseInt(value));
    }
    let temp2 = [];
    temp.sort().forEach((idx) => {
      temp2.push(txt_array[idx]);
    });
    // console.log(temp.join(" "));
    setInputCheckValueText(temp2.join(" "));
  };

  const changeSelectedPriority = (e) => {
    setSelectedPriority(parseInt(e.target.value));
  };

  const changeDestinationEF = (e) => {
    setDestinationExistingFilter(parseInt(e.target.value));
  };

  const toggleSearchExistingFilter = () => {
    setSearchExistingFilter(!searchExistingFilter);
    setListFilterToShow([]);
  };

  const buildSearchFilterList = (val) => {
    if (val.length > 0) {
      let listToShow = [];
      existFilters.forEach((itm, idx) => {
        if (itm.filter_name.toLowerCase().includes(val)) {
          let dtf = [itm.filter_name, idx];
          listToShow.push(dtf);
        }
      });

      // No match
      if (listToShow.length === 0) {
        setListFilterToShow(["No matching filter"]);
      } else {
        setListFilterToShow(listToShow);
      }
      // console.log(listToShow);
      // console.log(listFilterToShow);
    } else {
      setListFilterToShow([]);
    }
  };

  const selectFiltered = (idx) => {
    setDestinationExistingFilter(idx);
    toggleSearchExistingFilter();
  };

  const selectionBuilder = () => {
    if (keyword.idxa) {
      txt_array = keyword.keyword.keyword.split(" ");
      objKwrdFix = keyword.keyword;
    } else {
      txt_array = keyword.keyword.split(" ");
      objKwrdFix = keyword;
    }

    return (
      <div>
        {txt_array.map((itm, i) => {
          return (
            <div
              className='form-check bm-2'
              key={i}>
              <input
                className='form-check-input'
                type='checkbox'
                name='languages'
                value={i}
                id={itm}
                onChange={(e) => {
                  appendToInputFilter(e);
                }}
              />
              <label
                className='form-check-label'
                htmlFor={itm}>
                {itm}{" "}
                {objKwrdFix.translation.length > 0 &&
                  !objKwrdFix.translation[0].includes(
                    "MYMEMORY WARNING: YOU USED ALL AVAILABLE FREE TRANSLATIONS FOR TODAY."
                  ) && (
                    <span className='text-muted small fst-italic'>
                      ({objKwrdFix.translation[i + 1]})
                    </span>
                  )}
              </label>
            </div>
          );
        })}
      </div>
    );
  };

  const fToggler = () => {
    setCheckFilterValue({
      checkedList: [],
    });
    setSelectedPriority(-1);
    setDestinationExistingFilter(-1);
    setInputFilterValue("");
    setInputCheckValueText("");
    setListFilterToShow([]);
    setSearchExistingFilter(false);
    fnToggler();
  };

  return (
    <Modal
      show={modalState}
      //   onHide={handleClose}
      animation={false}>
      <Modal.Header className='py-2 px-4 c_modal_header'>
        <Modal.Title>Add Filter</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className='text-muted c_center'>
          ----------------- Destination -----------------
        </span>
        <p className='text-muted mb-1'>To pre-set filter :</p>
        <Form.Select
          size='sm'
          className='mb-2'
          onChange={(e) => {
            changeSelectedPriority(e);
          }}>
          <option value='-1'>None</option>
          {presetFilters.map((itm, i) => {
            return (
              <option
                value={i}
                key={i}>
                {itm}
              </option>
            );
          })}
        </Form.Select>{" "}
        <p className='text-muted mb-1'>To existing filter :</p>
        {searchExistingFilter ? (
          <>
            <div style={{ display: "flex" }}>
              <Form.Control
                autoFocus={true}
                size='sm'
                className='me-2'
                type='text'
                placeholder='search existing filter here ...'
                onChange={(e) => {
                  buildSearchFilterList(e.target.value);
                }}
              />
              <button
                className='btn btn-sm btn-danger'
                onClick={() => {
                  toggleSearchExistingFilter();
                }}>
                <span style={{ fontSize: "14px", margin: "0px 3px" }}>x</span>
              </button>
            </div>
            <div
              className='mt-2'
              style={{
                height: "max-content",
                maxHeight: "120px",
                overflowY: "auto",
                backgroundColor: "rgb(241 241 241)",
                borderRadius: "8px",
              }}>
              {listFilterToShow[0] === "No matching filter" && (
                <div
                  style={{ textAlign: "center" }}
                  className='text-muted'>
                  No matching filter
                </div>
              )}
              {listFilterToShow.length > 0 &&
                listFilterToShow[0] !== "No matching filter" && (
                  <ul
                    style={{
                      listStyle: "none",
                      padding: "6px 8px",
                      marginBottom: "0px",
                    }}>
                    {listFilterToShow.map((itm) => {
                      return (
                        <li
                          key={itm[1]}
                          className='c_pointer filtered_list_filter'
                          onClick={() => {
                            selectFiltered(itm[1]);
                          }}>
                          {itm[0]}
                        </li>
                      );
                    })}
                  </ul>
                )}
            </div>
          </>
        ) : (
          <div style={{ display: "flex" }}>
            <Form.Select
              size='sm'
              className='me-2'
              value={destinationExistingFilter}
              onChange={(e) => {
                changeDestinationEF(e);
              }}>
              <option value='-1'>None</option>
              {existFilters.map((itm, i) => {
                return (
                  <option
                    value={i}
                    key={i}>
                    {itm.filter_name}
                  </option>
                );
              })}
            </Form.Select>{" "}
            <button
              className='btn btn-sm btn-outline-info'
              onClick={() => {
                toggleSearchExistingFilter();
              }}>
              <Search />
            </button>
          </div>
        )}
        <span className='text-muted mt-3 c_center'>
          --------------------- Filter ---------------------
        </span>
        <p className='text-muted mb-1'>Type new filter :</p>
        <Form.Group
          className='mb-2'
          controlId='input_filter_typing'>
          <Form.Control
            size='sm'
            type='text'
            placeholder='type here ...'
            onChange={(e) => {
              setInputFilterValue(e.target.value);
            }}
          />
        </Form.Group>
        <p className='text-muted mb-1'>From keyword :</p>
        <div className='ms-3'>{selectionBuilder()}</div>
        {/* {checkFilterValue.checkedList.map((itm, k) => {
          return <span key={k}>{itm}</span>;
        })} */}
      </Modal.Body>
      <Modal.Footer>
        {(selectedPriority >= 0 || destinationExistingFilter >= 0) &&
        (inputCheckValueText !== "" || inputFilterValue !== "") ? (
          <Button
            variant='success'
            className='btn btn-sm'
            onClick={() => {
              buildFilter();
            }}>
            Register
          </Button>
        ) : (
          <Button
            variant='secondary'
            className='btn btn-sm disabled'>
            Register
          </Button>
        )}
        <Button
          variant='danger'
          className='btn btn-sm'
          onClick={() => fToggler()}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalFilter;
