import React from "react";
import {
  CircleFill,
  ListUl,
  PlusCircle,
  PlusCircleFill,
  InputCursorText,
  ChevronDoubleDown,
  ChevronDoubleUp,
  // XOctagonFill,
} from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  insert_group_to_map,
  append_to_group,
  create_sub,
  updateKeywordMapAddress,
  set_group_keyword_list_to_show,
  set_destination_add_manual_item_main_map,
  // unused_keyword,
  // fetchKeywords,
  nav_cursor,
} from "../features/keywords/keywordSlice";
import ButtonBox from "./ButtonBox";

const Mapper = ({
  objKeyword,
  togglerModalGroupKeywordList,
  togglerModalAddManualItemsMainMap,
  fnModalFilter,
  fnModalManageFilter,
  fnModalRemovedKeyword,
}) => {
  const dispatch = useDispatch();
  const defaultListMap = useSelector((state) => state.keywords.list_mapper);
  // const kList = useSelector((state) => state.keywords.keyword_list);
  const klLength = useSelector((state) => state.keywords.keyword_list.length);
  // const id_run = useSelector((state) => state.keywords.selected_run);
  // const loadingKeyword = useSelector(
  //   (state) => state.keywords.loading_keywords
  // );
  const listKeywordFilter = useSelector(
    (state) => state.keywords.keyword_list_with_filter
  );

  const totalKeywordLoaded = () => {
    let keywordSum = klLength;
    if (listKeywordFilter.length > 0) {
      listKeywordFilter.forEach((kf) => {
        keywordSum += kf.length;
      });
    }
    return keywordSum;
  };

  const insertKeyword = async (parents, group_index) => {
    if (objKeyword.map_address) {
      if (objKeyword.map_address.length > 0) {
        console.log("Keyword already added to map!");
      } else {
        dispatch(
          insert_group_to_map({
            id_keyword: objKeyword._id,
            parents: parents,
            keyword: objKeyword.keyword,
            volume: objKeyword.volume,
            group_index: group_index,
          })
        );
        // Move to next keyword
        let k_length = totalKeywordLoaded();
        dispatch(nav_cursor({ opr: 1, keyword_loaded: k_length }));
        updKwrdMapAddress(parents);
      }
    }
  };
  const appendToGroup = (destination, g_index) => {
    if (objKeyword.map_address) {
      let newGroupItem = {
        k: objKeyword.keyword,
        v: objKeyword.volume,
      };
      if (objKeyword.map_address.length > 0) {
        console.log("Keyword already added to map!");
      } else {
        dispatch(
          append_to_group({
            id_keyword: objKeyword._id,
            destination: destination,
            g_index: g_index,
            group_item: newGroupItem,
          })
        );
        // Move to next keyword
        let k_length = totalKeywordLoaded();
        dispatch(nav_cursor({ opr: 1, keyword_loaded: k_length }));
        updKwrdMapAddress(destination);
      }
    }
  };
  const createSub = (destination, g_index) => {
    if (objKeyword) {
      // console.log(destination, g_index);
      let newSubItems = {
        k: objKeyword.keyword,
        v: objKeyword.volume,
      };

      if (objKeyword.map_address.length > 0) {
        console.log("Keyword already added to map!");
      } else {
        dispatch(
          create_sub({
            id_keyword: objKeyword._id,
            destination: destination,
            g_index: g_index,
            subs_item: newSubItems,
          })
        );
        // Move to next keyword
        let k_length = totalKeywordLoaded();
        dispatch(nav_cursor({ opr: 1, keyword_loaded: k_length }));
        updKwrdMapAddress(destination);
      }
    }
  };
  const updKwrdMapAddress = (map_address) => {
    let post_data = {
      id_keyword: objKeyword._id,
      map_address: map_address,
    };

    dispatch(updateKeywordMapAddress(post_data));
  };
  const showKeywordListGroup = (k_list, address) => {
    togglerModalGroupKeywordList();
    dispatch(
      set_group_keyword_list_to_show({ keyword_list: k_list, address: address })
    );
  };
  const showModalAddItem = (destination) => {
    togglerModalAddManualItemsMainMap();
    dispatch(
      set_destination_add_manual_item_main_map({ destination: destination })
    );
  };

  const scrollElement = (dirc, target) => {
    let targetId = "scre-" + dirc + "-" + target;
    let targetEl = window.document.getElementById(targetId);
    targetEl.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  // const getOffset = () => {
  //   let offset = 0;
  //   kList.forEach((obj) => {
  //     if (!obj.map_address || obj.map_address.length === 0) {
  //       offset++;
  //     }
  //   });
  //   return offset;
  // };

  // const unusedKeyword = () => {
  //   let rOffset = getOffset();
  //   let post_data = {
  //     id_keyword: objKeyword._id,
  //     map_address: ["Unused"],
  //   };
  //   dispatch(updateKeywordMapAddress(post_data));

  //   dispatch(unused_keyword());
  //   if (klLength - 1 < 2) {
  //     dispatch(fetchKeywords({ _id: id_run, offset: rOffset }));
  //   }
  // };

  return (
    <div className='main_mapper'>
      <div className='main_mapper_title'>
        {objKeyword.map_address && (
          <div className='mapper_btn_box'>
            <ButtonBox
              objKeyword={objKeyword}
              fnToggle={fnModalFilter}
              fnToggleManage={fnModalManageFilter}
              fnModalRemovedKeyword={fnModalRemovedKeyword}
            />
          </div>
        )}
        <div className='mapper_title'>
          <h5 className='text-center mb-0'>
            <strong>
              <u>Main Map</u>
            </strong>
          </h5>
          {objKeyword.map_address ? (
            <>
              <p className='text-center text-muted small'>
                Active keyword :{" "}
                {objKeyword.map_address.length > 0 ? (
                  <>
                    <span className='text-success'>{objKeyword.keyword}</span>
                    <span className='text-muted small'>
                      {" "}
                      - ({objKeyword.map_address[0]})
                    </span>
                  </>
                ) : (
                  <>
                    <span className='text-danger'>{objKeyword.keyword} </span>
                    {/* {!loadingKeyword && (
                <button
                  className='btn btn-sm p-0'
                  onClick={() => {
                    unusedKeyword();
                  }}>
                  <XOctagonFill className='text-danger mb-1 ms-1'></XOctagonFill>
                </button>
              )} */}
                  </>
                )}
              </p>
            </>
          ) : (
            <p className='text-center text-muted small'>No active keyword</p>
          )}
        </div>
      </div>
      <div className='box-map'>
        <div className='box-map-items-container'>
          {defaultListMap.map((map_root, i) => {
            return (
              <div
                key={i}
                className='box-map-item'>
                <div className='map-item'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex align-items-center'>
                      <button
                        className='btn btn-sm p-0'
                        onClick={() => {
                          insertKeyword(
                            [map_root.map_name],
                            map_root.children.length
                          );
                        }}>
                        <PlusCircleFill
                          className='text-success'
                          style={{ fontSize: "18px" }}
                        />
                      </button>
                      <button
                        className='btn ms-2 btn-sm p-0'
                        onClick={() => {
                          showModalAddItem([i]);
                        }}>
                        <InputCursorText
                          className='text-success'
                          style={{ fontSize: "18px" }}
                        />
                      </button>
                      <p
                        className='ms-2 mb-0'
                        id={"scre-up-" + map_root.map_name}>
                        <strong>{map_root.map_name}</strong>
                      </p>
                    </div>
                    <div>
                      <ChevronDoubleDown
                        className='c_pointer'
                        onClick={() => {
                          scrollElement("down", map_root.map_name);
                        }}
                      />
                    </div>
                  </div>
                  {map_root.children.length > 0 && (
                    <div className='map-item-children'>
                      <div className='initial-g-line'></div>
                      {map_root.children.map((rc, rci) => {
                        return (
                          <div
                            key={rci}
                            className='g-line-parent'>
                            {rci < map_root.children.length - 1 && (
                              <div className='g-line'></div>
                            )}
                            <div className='map-item-children-itm'>
                              <CircleFill className='text-success dot-g-line' />
                              <p
                                className='mb-0 c_pointer'
                                onClick={() => {
                                  showKeywordListGroup(rc.keyword_list, [
                                    i,
                                    rci,
                                  ]);
                                }}>
                                {rc.keyword_list.length > 0
                                  ? rc.keyword_list[0].k
                                  : "<Empty>"}
                              </p>
                              <ListUl
                                className='ms-3 text-primary c_pointer'
                                onClick={() => {
                                  appendToGroup([map_root.map_name], rci);
                                }}
                              />
                              {/* {map_root.map_name !== "Product" && ( */}
                              <>
                                <PlusCircle
                                  className='ms-3 c_pointer text-warning'
                                  style={{ fontSize: "18px" }}
                                  onClick={() => {
                                    createSub([map_root.map_name], rci);
                                  }}
                                />
                                <button
                                  className='btn ms-3 btn-sm p-0'
                                  onClick={() => {
                                    showModalAddItem([i, rci]);
                                  }}>
                                  <InputCursorText
                                    className='text-success'
                                    style={{ fontSize: "18px" }}
                                  />
                                </button>
                              </>
                              {/* )} */}
                            </div>
                            {rc.subs.length > 0 && (
                              <div className='map-item-children'>
                                <div className='initial-g-line-subs'></div>
                                {rc.subs.map((s1, is1) => {
                                  return (
                                    <div
                                      className='g-line-parent'
                                      key={is1}>
                                      {is1 < rc.subs.length - 1 && (
                                        <div className='g-line'></div>
                                      )}
                                      <div className='map-item-children-itm'>
                                        <CircleFill className='text-warning dot-g-line' />
                                        <p
                                          className='mb-0 c_pointer'
                                          onClick={() => {
                                            showKeywordListGroup(
                                              s1.keyword_list,
                                              [i, rci, is1]
                                            );
                                          }}>
                                          {s1.keyword_list.length > 0
                                            ? s1.keyword_list[0].k
                                            : "<Empty>"}
                                        </p>
                                        <ListUl
                                          className='ms-3 text-primary c_pointer'
                                          onClick={() => {
                                            appendToGroup(
                                              [map_root.map_name, rci],
                                              is1
                                            );
                                          }}
                                        />
                                        {/* {map_root.map_name !== "Product" && ( */}
                                        <>
                                          <PlusCircle
                                            className='ms-3 c_pointer text-warning'
                                            style={{ fontSize: "18px" }}
                                            onClick={() => {
                                              createSub(
                                                [map_root.map_name, rci],
                                                is1
                                              );
                                            }}
                                          />
                                          <button
                                            className='btn ms-3 btn-sm p-0'
                                            onClick={() => {
                                              showModalAddItem([i, rci, is1]);
                                            }}>
                                            <InputCursorText
                                              className='text-success'
                                              style={{ fontSize: "18px" }}
                                            />
                                          </button>
                                        </>
                                        {/* )} */}
                                      </div>
                                      {s1.subs.length > 0 && (
                                        <div className='map-item-children'>
                                          <div className='initial-g-line-subs'></div>
                                          {s1.subs.map((s2, is2) => {
                                            return (
                                              <div
                                                className='g-line-parent'
                                                key={is2}>
                                                {is2 < s1.subs.length - 1 && (
                                                  <div className='g-line'></div>
                                                )}
                                                <div className='map-item-children-itm'>
                                                  <CircleFill className='text-warning dot-g-line' />
                                                  <p
                                                    className='mb-0 c_pointer'
                                                    onClick={() => {
                                                      showKeywordListGroup(
                                                        s2.keyword_list,
                                                        [i, rci, is1, is2]
                                                      );
                                                    }}>
                                                    {s2.keyword_list.length > 0
                                                      ? s2.keyword_list[0].k
                                                      : "<Empty>"}
                                                  </p>
                                                  <ListUl
                                                    className='ms-3 text-primary c_pointer'
                                                    onClick={() => {
                                                      appendToGroup(
                                                        [
                                                          map_root.map_name,
                                                          rci,
                                                          is1,
                                                        ],
                                                        is2
                                                      );
                                                    }}
                                                  />
                                                  {/* {map_root.map_name !==
                                                    "Product" && ( */}
                                                  <>
                                                    <PlusCircle
                                                      className='ms-3 c_pointer text-warning'
                                                      style={{
                                                        fontSize: "18px",
                                                      }}
                                                      onClick={() => {
                                                        createSub(
                                                          [
                                                            map_root.map_name,
                                                            rci,
                                                            is1,
                                                          ],
                                                          is2
                                                        );
                                                      }}
                                                    />
                                                    <button
                                                      className='btn ms-3 btn-sm p-0'
                                                      onClick={() => {
                                                        showModalAddItem([
                                                          i,
                                                          rci,
                                                          is1,
                                                          is2,
                                                        ]);
                                                      }}>
                                                      <InputCursorText
                                                        className='text-success'
                                                        style={{
                                                          fontSize: "18px",
                                                        }}
                                                      />
                                                    </button>
                                                  </>
                                                  {/* )} */}
                                                </div>
                                                {s2.subs.length > 0 && (
                                                  <div className='map-item-children'>
                                                    <div className='initial-g-line-subs'></div>
                                                    {s2.subs.map((s3, is3) => {
                                                      return (
                                                        <div
                                                          className='g-line-parent'
                                                          key={is3}>
                                                          {is3 <
                                                            s2.subs.length -
                                                              1 && (
                                                            <div className='g-line'></div>
                                                          )}
                                                          <div className='map-item-children-itm'>
                                                            <CircleFill className='text-warning dot-g-line' />
                                                            <p
                                                              className='mb-0 c_pointer'
                                                              onClick={() => {
                                                                showKeywordListGroup(
                                                                  s3.keyword_list,
                                                                  [
                                                                    i,
                                                                    rci,
                                                                    is1,
                                                                    is2,
                                                                    is3,
                                                                  ]
                                                                );
                                                              }}>
                                                              {s3.keyword_list
                                                                .length > 0
                                                                ? s3
                                                                    .keyword_list[0]
                                                                    .k
                                                                : "<Empty>"}
                                                            </p>
                                                            <ListUl
                                                              className='ms-3 text-primary c_pointer'
                                                              onClick={() => {
                                                                appendToGroup(
                                                                  [
                                                                    map_root.map_name,
                                                                    rci,
                                                                    is1,
                                                                    is2,
                                                                  ],
                                                                  is3
                                                                );
                                                              }}
                                                            />
                                                            {/* {map_root.map_name !==
                                                              "Product" && ( */}
                                                            <>
                                                              <PlusCircle
                                                                className='ms-3 c_pointer text-warning'
                                                                style={{
                                                                  fontSize:
                                                                    "18px",
                                                                }}
                                                                onClick={() => {
                                                                  createSub(
                                                                    [
                                                                      map_root.map_name,
                                                                      rci,
                                                                      is1,
                                                                      is2,
                                                                    ],
                                                                    is3
                                                                  );
                                                                }}
                                                              />
                                                              <button
                                                                className='btn ms-3 btn-sm p-0'
                                                                onClick={() => {
                                                                  showModalAddItem(
                                                                    [
                                                                      i,
                                                                      rci,
                                                                      is1,
                                                                      is2,
                                                                      is3,
                                                                    ]
                                                                  );
                                                                }}>
                                                                <InputCursorText
                                                                  className='text-success'
                                                                  style={{
                                                                    fontSize:
                                                                      "18px",
                                                                  }}
                                                                />
                                                              </button>
                                                            </>
                                                            {/* )} */}
                                                          </div>
                                                          {s3.subs.length >
                                                            0 && (
                                                            <div className='map-item-children'>
                                                              <div className='initial-g-line-subs'></div>
                                                              {s3.subs.map(
                                                                (s4, is4) => {
                                                                  return (
                                                                    <div
                                                                      className='g-line-parent'
                                                                      key={is4}>
                                                                      {is4 <
                                                                        s3.subs
                                                                          .length -
                                                                          1 && (
                                                                        <div className='g-line'></div>
                                                                      )}
                                                                      <div className='map-item-children-itm'>
                                                                        <CircleFill className='text-warning dot-g-line' />
                                                                        <p
                                                                          className='mb-0 c_pointer'
                                                                          onClick={() => {
                                                                            showKeywordListGroup(
                                                                              s4.keyword_list,
                                                                              [
                                                                                i,
                                                                                rci,
                                                                                is1,
                                                                                is2,
                                                                                is3,
                                                                                is4,
                                                                              ]
                                                                            );
                                                                          }}>
                                                                          {s4
                                                                            .keyword_list
                                                                            .length >
                                                                          0
                                                                            ? s4
                                                                                .keyword_list[0]
                                                                                .k
                                                                            : "<Empty>"}
                                                                        </p>
                                                                        <ListUl
                                                                          className='ms-3 text-primary c_pointer'
                                                                          onClick={() => {
                                                                            appendToGroup(
                                                                              [
                                                                                map_root.map_name,
                                                                                rci,
                                                                                is1,
                                                                                is2,
                                                                                is3,
                                                                              ],
                                                                              is4
                                                                            );
                                                                          }}
                                                                        />
                                                                        {/* {map_root.map_name !==
                                                                          "Product" && ( */}
                                                                        <>
                                                                          <PlusCircle
                                                                            className='ms-3 c_pointer text-warning'
                                                                            style={{
                                                                              fontSize:
                                                                                "18px",
                                                                            }}
                                                                            onClick={() => {
                                                                              createSub(
                                                                                [
                                                                                  map_root.map_name,
                                                                                  rci,
                                                                                  is1,
                                                                                  is2,
                                                                                  is3,
                                                                                ],
                                                                                is4
                                                                              );
                                                                            }}
                                                                          />
                                                                          <button
                                                                            className='btn ms-3 btn-sm p-0'
                                                                            onClick={() => {
                                                                              showModalAddItem(
                                                                                [
                                                                                  i,
                                                                                  rci,
                                                                                  is1,
                                                                                  is2,
                                                                                  is3,
                                                                                  is4,
                                                                                ]
                                                                              );
                                                                            }}>
                                                                            <InputCursorText
                                                                              className='text-success'
                                                                              style={{
                                                                                fontSize:
                                                                                  "18px",
                                                                              }}
                                                                            />
                                                                          </button>
                                                                        </>
                                                                        {/* )} */}
                                                                      </div>
                                                                      {s4.subs
                                                                        .length >
                                                                        0 && (
                                                                        <div className='map-item-children'>
                                                                          <div className='initial-g-line-subs'></div>
                                                                          {s4.subs.map(
                                                                            (
                                                                              s5,
                                                                              is5
                                                                            ) => {
                                                                              return (
                                                                                <div
                                                                                  className='g-line-parent'
                                                                                  key={
                                                                                    is5
                                                                                  }>
                                                                                  {is5 <
                                                                                    s4
                                                                                      .subs
                                                                                      .length -
                                                                                      1 && (
                                                                                    <div className='g-line'></div>
                                                                                  )}
                                                                                  <div className='map-item-children-itm'>
                                                                                    <CircleFill className='text-warning dot-g-line' />
                                                                                    <p
                                                                                      className='mb-0 c_pointer'
                                                                                      onClick={() => {
                                                                                        showKeywordListGroup(
                                                                                          s5.keyword_list,
                                                                                          [
                                                                                            i,
                                                                                            rci,
                                                                                            is1,
                                                                                            is2,
                                                                                            is3,
                                                                                            is4,
                                                                                            is5,
                                                                                          ]
                                                                                        );
                                                                                      }}>
                                                                                      {s5
                                                                                        .keyword_list
                                                                                        .length >
                                                                                      0
                                                                                        ? s5
                                                                                            .keyword_list[0]
                                                                                            .k
                                                                                        : "<Empty>"}
                                                                                    </p>
                                                                                    <ListUl
                                                                                      className='ms-3 text-primary c_pointer'
                                                                                      onClick={() => {
                                                                                        appendToGroup(
                                                                                          [
                                                                                            map_root.map_name,
                                                                                            rci,
                                                                                            is1,
                                                                                            is2,
                                                                                            is3,
                                                                                            is4,
                                                                                          ],
                                                                                          is5
                                                                                        );
                                                                                      }}
                                                                                    />
                                                                                    {/* {map_root.map_name !==
                                                                                      "Product" && ( */}
                                                                                    <>
                                                                                      <PlusCircle
                                                                                        className='ms-3 c_pointer text-warning'
                                                                                        style={{
                                                                                          fontSize:
                                                                                            "18px",
                                                                                        }}
                                                                                        onClick={() => {
                                                                                          createSub(
                                                                                            [
                                                                                              map_root.map_name,
                                                                                              rci,
                                                                                              is1,
                                                                                              is2,
                                                                                              is3,
                                                                                              is4,
                                                                                            ],
                                                                                            is5
                                                                                          );
                                                                                        }}
                                                                                      />
                                                                                      <button
                                                                                        className='btn ms-3 btn-sm p-0'
                                                                                        onClick={() => {
                                                                                          showModalAddItem(
                                                                                            [
                                                                                              i,
                                                                                              rci,
                                                                                              is1,
                                                                                              is2,
                                                                                              is3,
                                                                                              is4,
                                                                                              is5,
                                                                                            ]
                                                                                          );
                                                                                        }}>
                                                                                        <InputCursorText
                                                                                          className='text-success'
                                                                                          style={{
                                                                                            fontSize:
                                                                                              "18px",
                                                                                          }}
                                                                                        />
                                                                                      </button>
                                                                                    </>
                                                                                    {/* )} */}
                                                                                  </div>
                                                                                  {s5
                                                                                    .subs
                                                                                    .length >
                                                                                    0 && (
                                                                                    <div className='map-item-children'>
                                                                                      <div className='initial-g-line-subs'></div>
                                                                                      {s5.subs.map(
                                                                                        (
                                                                                          s6,
                                                                                          is6
                                                                                        ) => {
                                                                                          return (
                                                                                            <div
                                                                                              className='g-line-parent'
                                                                                              key={
                                                                                                is6
                                                                                              }>
                                                                                              {is6 <
                                                                                                s5
                                                                                                  .subs
                                                                                                  .length -
                                                                                                  1 && (
                                                                                                <div className='g-line'></div>
                                                                                              )}
                                                                                              <div className='map-item-children-itm'>
                                                                                                <CircleFill className='text-warning dot-g-line' />
                                                                                                <p
                                                                                                  className='mb-0 c_pointer'
                                                                                                  onClick={() => {
                                                                                                    showKeywordListGroup(
                                                                                                      s6.keyword_list,
                                                                                                      [
                                                                                                        i,
                                                                                                        rci,
                                                                                                        is1,
                                                                                                        is2,
                                                                                                        is3,
                                                                                                        is4,
                                                                                                        is5,
                                                                                                        is6,
                                                                                                      ]
                                                                                                    );
                                                                                                  }}>
                                                                                                  {s6
                                                                                                    .keyword_list
                                                                                                    .length >
                                                                                                  0
                                                                                                    ? s6
                                                                                                        .keyword_list[0]
                                                                                                        .k
                                                                                                    : "<Empty>"}
                                                                                                </p>
                                                                                                <ListUl
                                                                                                  className='ms-3 text-primary c_pointer'
                                                                                                  onClick={() => {
                                                                                                    appendToGroup(
                                                                                                      [
                                                                                                        map_root.map_name,
                                                                                                        rci,
                                                                                                        is1,
                                                                                                        is2,
                                                                                                        is3,
                                                                                                        is4,
                                                                                                        is5,
                                                                                                      ],
                                                                                                      is6
                                                                                                    );
                                                                                                  }}
                                                                                                />
                                                                                                {/* {map_root.map_name !==
                                                                                                  "Product" && ( */}
                                                                                                <>
                                                                                                  <PlusCircle
                                                                                                    className='ms-3 c_pointer text-warning'
                                                                                                    style={{
                                                                                                      fontSize:
                                                                                                        "18px",
                                                                                                    }}
                                                                                                    onClick={() => {
                                                                                                      createSub(
                                                                                                        [
                                                                                                          map_root.map_name,
                                                                                                          rci,
                                                                                                          is1,
                                                                                                          is2,
                                                                                                          is3,
                                                                                                          is4,
                                                                                                          is5,
                                                                                                        ],
                                                                                                        is6
                                                                                                      );
                                                                                                    }}
                                                                                                  />
                                                                                                  <button
                                                                                                    className='btn ms-3 btn-sm p-0'
                                                                                                    onClick={() => {
                                                                                                      showModalAddItem(
                                                                                                        [
                                                                                                          i,
                                                                                                          rci,
                                                                                                          is1,
                                                                                                          is2,
                                                                                                          is3,
                                                                                                          is4,
                                                                                                          is5,
                                                                                                          is6,
                                                                                                        ]
                                                                                                      );
                                                                                                    }}>
                                                                                                    <InputCursorText
                                                                                                      className='text-success'
                                                                                                      style={{
                                                                                                        fontSize:
                                                                                                          "18px",
                                                                                                      }}
                                                                                                    />
                                                                                                  </button>
                                                                                                </>
                                                                                                {/* )} */}
                                                                                              </div>
                                                                                              {s6
                                                                                                .subs
                                                                                                .length >
                                                                                                0 && (
                                                                                                <div className='map-item-children'>
                                                                                                  <div className='initial-g-line-subs'></div>
                                                                                                  {s6.subs.map(
                                                                                                    (
                                                                                                      s7,
                                                                                                      is7
                                                                                                    ) => {
                                                                                                      return (
                                                                                                        <div
                                                                                                          className='g-line-parent'
                                                                                                          key={
                                                                                                            is7
                                                                                                          }>
                                                                                                          {is7 <
                                                                                                            s6
                                                                                                              .subs
                                                                                                              .length -
                                                                                                              1 && (
                                                                                                            <div className='g-line'></div>
                                                                                                          )}
                                                                                                          <div className='map-item-children-itm'>
                                                                                                            <CircleFill className='text-warning dot-g-line' />
                                                                                                            <p
                                                                                                              className='mb-0 c_pointer'
                                                                                                              onClick={() => {
                                                                                                                showKeywordListGroup(
                                                                                                                  s7.keyword_list,
                                                                                                                  [
                                                                                                                    i,
                                                                                                                    rci,
                                                                                                                    is1,
                                                                                                                    is2,
                                                                                                                    is3,
                                                                                                                    is4,
                                                                                                                    is5,
                                                                                                                    is6,
                                                                                                                    is7,
                                                                                                                  ]
                                                                                                                );
                                                                                                              }}>
                                                                                                              {s7
                                                                                                                .keyword_list
                                                                                                                .length >
                                                                                                              0
                                                                                                                ? s7
                                                                                                                    .keyword_list[0]
                                                                                                                    .k
                                                                                                                : "<Empty>"}
                                                                                                            </p>
                                                                                                            <ListUl
                                                                                                              className='ms-3 text-primary c_pointer'
                                                                                                              onClick={() => {
                                                                                                                appendToGroup(
                                                                                                                  [
                                                                                                                    map_root.map_name,
                                                                                                                    rci,
                                                                                                                    is1,
                                                                                                                    is2,
                                                                                                                    is3,
                                                                                                                    is4,
                                                                                                                    is5,
                                                                                                                    is6,
                                                                                                                  ],
                                                                                                                  is7
                                                                                                                );
                                                                                                              }}
                                                                                                            />
                                                                                                            {/* {map_root.map_name !==
                                                                                                              "Product" && ( */}
                                                                                                            <>
                                                                                                              <PlusCircle
                                                                                                                className='ms-3 c_pointer text-warning'
                                                                                                                style={{
                                                                                                                  fontSize:
                                                                                                                    "18px",
                                                                                                                }}
                                                                                                                onClick={() => {
                                                                                                                  createSub(
                                                                                                                    [
                                                                                                                      map_root.map_name,
                                                                                                                      rci,
                                                                                                                      is1,
                                                                                                                      is2,
                                                                                                                      is3,
                                                                                                                      is4,
                                                                                                                      is5,
                                                                                                                      is6,
                                                                                                                    ],
                                                                                                                    is7
                                                                                                                  );
                                                                                                                }}
                                                                                                              />
                                                                                                              <button
                                                                                                                className='btn ms-3 btn-sm p-0'
                                                                                                                onClick={() => {
                                                                                                                  showModalAddItem(
                                                                                                                    [
                                                                                                                      i,
                                                                                                                      rci,
                                                                                                                      is1,
                                                                                                                      is2,
                                                                                                                      is3,
                                                                                                                      is4,
                                                                                                                      is5,
                                                                                                                      is6,
                                                                                                                      is7,
                                                                                                                    ]
                                                                                                                  );
                                                                                                                }}>
                                                                                                                <InputCursorText
                                                                                                                  className='text-success'
                                                                                                                  style={{
                                                                                                                    fontSize:
                                                                                                                      "18px",
                                                                                                                  }}
                                                                                                                />
                                                                                                              </button>
                                                                                                            </>
                                                                                                            {/* )} */}
                                                                                                          </div>
                                                                                                          {s7
                                                                                                            .subs
                                                                                                            .length >
                                                                                                            0 && (
                                                                                                            <div className='map-item-children'>
                                                                                                              <div className='initial-g-line-subs'></div>
                                                                                                              {s7.subs.map(
                                                                                                                (
                                                                                                                  s8,
                                                                                                                  is8
                                                                                                                ) => {
                                                                                                                  return (
                                                                                                                    <div
                                                                                                                      className='g-line-parent'
                                                                                                                      key={
                                                                                                                        is8
                                                                                                                      }>
                                                                                                                      {is8 <
                                                                                                                        s7
                                                                                                                          .subs
                                                                                                                          .length -
                                                                                                                          1 && (
                                                                                                                        <div className='g-line'></div>
                                                                                                                      )}
                                                                                                                      <div className='map-item-children-itm'>
                                                                                                                        <CircleFill className='text-warning dot-g-line' />
                                                                                                                        <p
                                                                                                                          className='mb-0 c_pointer'
                                                                                                                          onClick={() => {
                                                                                                                            showKeywordListGroup(
                                                                                                                              s8.keyword_list,
                                                                                                                              [
                                                                                                                                i,
                                                                                                                                rci,
                                                                                                                                is1,
                                                                                                                                is2,
                                                                                                                                is3,
                                                                                                                                is4,
                                                                                                                                is5,
                                                                                                                                is6,
                                                                                                                                is7,
                                                                                                                                is8,
                                                                                                                              ]
                                                                                                                            );
                                                                                                                          }}>
                                                                                                                          {s8
                                                                                                                            .keyword_list
                                                                                                                            .length >
                                                                                                                          0
                                                                                                                            ? s8
                                                                                                                                .keyword_list[0]
                                                                                                                                .k
                                                                                                                            : "<Empty>"}
                                                                                                                        </p>
                                                                                                                        <ListUl
                                                                                                                          className='ms-3 text-primary c_pointer'
                                                                                                                          onClick={() => {
                                                                                                                            appendToGroup(
                                                                                                                              [
                                                                                                                                map_root.map_name,
                                                                                                                                rci,
                                                                                                                                is1,
                                                                                                                                is2,
                                                                                                                                is3,
                                                                                                                                is4,
                                                                                                                                is5,
                                                                                                                                is6,
                                                                                                                                is7,
                                                                                                                              ],
                                                                                                                              is8
                                                                                                                            );
                                                                                                                          }}
                                                                                                                        />
                                                                                                                        {/* {map_root.map_name !==
                                                                                                                          "Product" && ( */}
                                                                                                                        <>
                                                                                                                          <PlusCircle
                                                                                                                            className='ms-3 c_pointer text-warning'
                                                                                                                            style={{
                                                                                                                              fontSize:
                                                                                                                                "18px",
                                                                                                                            }}
                                                                                                                            onClick={() => {
                                                                                                                              createSub(
                                                                                                                                [
                                                                                                                                  map_root.map_name,
                                                                                                                                  rci,
                                                                                                                                  is1,
                                                                                                                                  is2,
                                                                                                                                  is3,
                                                                                                                                  is4,
                                                                                                                                  is5,
                                                                                                                                  is6,
                                                                                                                                  is7,
                                                                                                                                ],
                                                                                                                                is8
                                                                                                                              );
                                                                                                                            }}
                                                                                                                          />
                                                                                                                          <button
                                                                                                                            className='btn ms-3 btn-sm p-0'
                                                                                                                            onClick={() => {
                                                                                                                              showModalAddItem(
                                                                                                                                [
                                                                                                                                  i,
                                                                                                                                  rci,
                                                                                                                                  is1,
                                                                                                                                  is2,
                                                                                                                                  is3,
                                                                                                                                  is4,
                                                                                                                                  is5,
                                                                                                                                  is6,
                                                                                                                                  is7,
                                                                                                                                  is8,
                                                                                                                                ]
                                                                                                                              );
                                                                                                                            }}>
                                                                                                                            <InputCursorText
                                                                                                                              className='text-success'
                                                                                                                              style={{
                                                                                                                                fontSize:
                                                                                                                                  "18px",
                                                                                                                              }}
                                                                                                                            />
                                                                                                                          </button>
                                                                                                                        </>
                                                                                                                        {/* )} */}
                                                                                                                      </div>
                                                                                                                      {s8
                                                                                                                        .subs
                                                                                                                        .length >
                                                                                                                        0 && (
                                                                                                                        <div className='map-item-children'>
                                                                                                                          <div className='initial-g-line-subs'></div>
                                                                                                                          {s8.subs.map(
                                                                                                                            (
                                                                                                                              s9,
                                                                                                                              is9
                                                                                                                            ) => {
                                                                                                                              return (
                                                                                                                                <div
                                                                                                                                  className='g-line-parent'
                                                                                                                                  key={
                                                                                                                                    is9
                                                                                                                                  }>
                                                                                                                                  {is9 <
                                                                                                                                    s8
                                                                                                                                      .subs
                                                                                                                                      .length -
                                                                                                                                      1 && (
                                                                                                                                    <div className='g-line'></div>
                                                                                                                                  )}
                                                                                                                                  <div className='map-item-children-itm'>
                                                                                                                                    <CircleFill className='text-warning dot-g-line' />
                                                                                                                                    <p
                                                                                                                                      className='mb-0 c_pointer'
                                                                                                                                      onClick={() => {
                                                                                                                                        showKeywordListGroup(
                                                                                                                                          s9.keyword_list,
                                                                                                                                          [
                                                                                                                                            i,
                                                                                                                                            rci,
                                                                                                                                            is1,
                                                                                                                                            is2,
                                                                                                                                            is3,
                                                                                                                                            is4,
                                                                                                                                            is5,
                                                                                                                                            is6,
                                                                                                                                            is7,
                                                                                                                                            is8,
                                                                                                                                            is9,
                                                                                                                                          ]
                                                                                                                                        );
                                                                                                                                      }}>
                                                                                                                                      {s9
                                                                                                                                        .keyword_list
                                                                                                                                        .length >
                                                                                                                                      0
                                                                                                                                        ? s9
                                                                                                                                            .keyword_list[0]
                                                                                                                                            .k
                                                                                                                                        : "<Empty>"}
                                                                                                                                    </p>
                                                                                                                                    <ListUl
                                                                                                                                      className='ms-3 text-primary c_pointer'
                                                                                                                                      onClick={() => {
                                                                                                                                        appendToGroup(
                                                                                                                                          [
                                                                                                                                            map_root.map_name,
                                                                                                                                            rci,
                                                                                                                                            is1,
                                                                                                                                            is2,
                                                                                                                                            is3,
                                                                                                                                            is4,
                                                                                                                                            is5,
                                                                                                                                            is6,
                                                                                                                                            is7,
                                                                                                                                            is8,
                                                                                                                                          ],
                                                                                                                                          is9
                                                                                                                                        );
                                                                                                                                      }}
                                                                                                                                    />
                                                                                                                                    {/* {map_root.map_name !==
                                                                                                                                      "Product" && ( */}
                                                                                                                                    <>
                                                                                                                                      <PlusCircle
                                                                                                                                        className='ms-3 c_pointer text-warning'
                                                                                                                                        style={{
                                                                                                                                          fontSize:
                                                                                                                                            "18px",
                                                                                                                                        }}
                                                                                                                                        onClick={() => {
                                                                                                                                          createSub(
                                                                                                                                            [
                                                                                                                                              map_root.map_name,
                                                                                                                                              rci,
                                                                                                                                              is1,
                                                                                                                                              is2,
                                                                                                                                              is3,
                                                                                                                                              is4,
                                                                                                                                              is5,
                                                                                                                                              is6,
                                                                                                                                              is7,
                                                                                                                                              is8,
                                                                                                                                            ],
                                                                                                                                            is9
                                                                                                                                          );
                                                                                                                                        }}
                                                                                                                                      />
                                                                                                                                      <button
                                                                                                                                        className='btn ms-3 btn-sm p-0'
                                                                                                                                        onClick={() => {
                                                                                                                                          showModalAddItem(
                                                                                                                                            [
                                                                                                                                              i,
                                                                                                                                              rci,
                                                                                                                                              is1,
                                                                                                                                              is2,
                                                                                                                                              is3,
                                                                                                                                              is4,
                                                                                                                                              is5,
                                                                                                                                              is6,
                                                                                                                                              is7,
                                                                                                                                              is8,
                                                                                                                                              is9,
                                                                                                                                            ]
                                                                                                                                          );
                                                                                                                                        }}>
                                                                                                                                        <InputCursorText
                                                                                                                                          className='text-success'
                                                                                                                                          style={{
                                                                                                                                            fontSize:
                                                                                                                                              "18px",
                                                                                                                                          }}
                                                                                                                                        />
                                                                                                                                      </button>
                                                                                                                                    </>
                                                                                                                                    {/* )} */}
                                                                                                                                  </div>
                                                                                                                                  {s9
                                                                                                                                    .subs
                                                                                                                                    .length >
                                                                                                                                    0 && (
                                                                                                                                    <div className='map-item-children'>
                                                                                                                                      <div className='initial-g-line-subs'></div>
                                                                                                                                      {s9.subs.map(
                                                                                                                                        (
                                                                                                                                          s10,
                                                                                                                                          is10
                                                                                                                                        ) => {
                                                                                                                                          return (
                                                                                                                                            <div
                                                                                                                                              className='g-line-parent'
                                                                                                                                              key={
                                                                                                                                                is10
                                                                                                                                              }>
                                                                                                                                              {is10 <
                                                                                                                                                s9
                                                                                                                                                  .subs
                                                                                                                                                  .length -
                                                                                                                                                  1 && (
                                                                                                                                                <div className='g-line'></div>
                                                                                                                                              )}
                                                                                                                                              <div className='map-item-children-itm'>
                                                                                                                                                <CircleFill className='text-warning dot-g-line' />
                                                                                                                                                <p
                                                                                                                                                  className='mb-0 c_pointer'
                                                                                                                                                  onClick={() => {
                                                                                                                                                    showKeywordListGroup(
                                                                                                                                                      s10.keyword_list,
                                                                                                                                                      [
                                                                                                                                                        i,
                                                                                                                                                        rci,
                                                                                                                                                        is1,
                                                                                                                                                        is2,
                                                                                                                                                        is3,
                                                                                                                                                        is4,
                                                                                                                                                        is5,
                                                                                                                                                        is6,
                                                                                                                                                        is7,
                                                                                                                                                        is8,
                                                                                                                                                        is9,
                                                                                                                                                        is10,
                                                                                                                                                      ]
                                                                                                                                                    );
                                                                                                                                                  }}>
                                                                                                                                                  {s10
                                                                                                                                                    .keyword_list
                                                                                                                                                    .length >
                                                                                                                                                  0
                                                                                                                                                    ? s10
                                                                                                                                                        .keyword_list[0]
                                                                                                                                                        .k
                                                                                                                                                    : "<Empty>"}
                                                                                                                                                </p>
                                                                                                                                                <ListUl
                                                                                                                                                  className='ms-3 text-primary c_pointer'
                                                                                                                                                  onClick={() => {
                                                                                                                                                    appendToGroup(
                                                                                                                                                      [
                                                                                                                                                        map_root.map_name,
                                                                                                                                                        rci,
                                                                                                                                                        is1,
                                                                                                                                                        is2,
                                                                                                                                                        is3,
                                                                                                                                                        is4,
                                                                                                                                                        is5,
                                                                                                                                                        is6,
                                                                                                                                                        is7,
                                                                                                                                                        is8,
                                                                                                                                                        is9,
                                                                                                                                                      ],
                                                                                                                                                      is10
                                                                                                                                                    );
                                                                                                                                                  }}
                                                                                                                                                />
                                                                                                                                                {/* {map_root.map_name !==
                                                                                                                                                  "Product" && (
                                                                                                                                                  <PlusCircle
                                                                                                                                                    className='ms-3 c_pointer text-warning'
                                                                                                                                                    style={{
                                                                                                                                                      fontSize:
                                                                                                                                                        "18px",
                                                                                                                                                    }}
                                                                                                                                                    onClick={() => {
                                                                                                                                                      createSub(
                                                                                                                                                        [
                                                                                                                                                          map_root.map_name,
                                                                                                                                                          rci,
                                                                                                                                                          is1,
                                                                                                                                                          is2,
                                                                                                                                                          is3,
                                                                                                                                                          is4,
                                                                                                                                                          is5,
                                                                                                                                                          is6,
                                                                                                                                                          is7,
                                                                                                                                                          is8,
                                                                                                                                                          is9,
                                                                                                                                                        ],
                                                                                                                                                        is10
                                                                                                                                                      );
                                                                                                                                                    }}
                                                                                                                                                  />
                                                                                                                                                )} */}
                                                                                                                                              </div>
                                                                                                                                            </div>
                                                                                                                                          );
                                                                                                                                        }
                                                                                                                                      )}
                                                                                                                                    </div>
                                                                                                                                  )}
                                                                                                                                </div>
                                                                                                                              );
                                                                                                                            }
                                                                                                                          )}
                                                                                                                        </div>
                                                                                                                      )}
                                                                                                                    </div>
                                                                                                                  );
                                                                                                                }
                                                                                                              )}
                                                                                                            </div>
                                                                                                          )}
                                                                                                        </div>
                                                                                                      );
                                                                                                    }
                                                                                                  )}
                                                                                                </div>
                                                                                              )}
                                                                                            </div>
                                                                                          );
                                                                                        }
                                                                                      )}
                                                                                    </div>
                                                                                  )}
                                                                                </div>
                                                                              );
                                                                            }
                                                                          )}
                                                                        </div>
                                                                      )}
                                                                    </div>
                                                                  );
                                                                }
                                                              )}
                                                            </div>
                                                          )}
                                                        </div>
                                                      );
                                                    })}
                                                  </div>
                                                )}
                                              </div>
                                            );
                                          })}
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                            {/* Visual list item group */}
                            {/* {rc.keyword_list.map((ex, ix) => {
                              return (
                                <p
                                  key={ix}
                                  className='text-muted small mb-0'>
                                  {ex.k} - {ex.v}
                                </p>
                              );
                            })} */}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <div
                    id={"scre-down-" + map_root.map_name}
                    className='d-flex align-items-center justify-content-end'>
                    <ChevronDoubleUp
                      className='c_pointer'
                      onClick={() => {
                        scrollElement("up", map_root.map_name);
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Mapper;
