import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchKeywords,
  reset_keyword_cursor,
  fetchMapper,
  fetchDatasetKeywordsInformation,
} from "../features/keywords/keywordSlice";

const FilterLoader = () => {
  const dispatch = useDispatch();
  const listFilter = useSelector((state) => state.keywords.list_filters);
  const idRun = useSelector((state) => state.keywords.selected_run);
  const loadingRun = useSelector((state) => state.keywords.loading_keywords);

  const renderElement = () => {
    if (listFilter.length > 0) {
      // if (!loadingRun) {
      //   let e = idRun;
      //   dispatch(reset_keyword_cursor());
      //   dispatch(fetchKeywords({ _id: e, offset: 0 }));
      //   dispatch(fetchMapper({ _id: e }));
      //   dispatch(fetchDatasetKeywordsInformation({ _id: e }));
      // }
      return (
        <div className='alert alert-info text-center p-2 m-3'>
          Pre-Loading keyword list done!
        </div>
      );
    } else {
      return (
        <div className='alert alert-info text-center p-2 m-3'>
          Pre-Loading keyword list in progress ...
        </div>
      );
    }
  };

  return <>{renderElement()}</>;
};

export default FilterLoader;
