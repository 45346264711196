import React from "react";
import { useSelector } from "react-redux";

const DatasetKeywordInormation = () => {
  const totalKeyword = useSelector(
    (state) => state.keywords.total_keyword_of_dataset
  );
  const totalKeywordProcessed = useSelector(
    (state) => state.keywords.total_keyword_processed_of_dataset
  );

  const getOutstandingKeyword = () => {
    if (totalKeyword !== "-" && totalKeywordProcessed !== "-") {
      return totalKeyword - totalKeywordProcessed;
    } else {
      return "-";
    }
  };
  return (
    <div className='sect-dataset-keyword-information'>
      Total Keyword : {totalKeyword} || Processed Keyword :{" "}
      {totalKeywordProcessed} || Outstanding Keyword : {getOutstandingKeyword()}
    </div>
  );
};

export default DatasetKeywordInormation;
