import {
  Check,
  ChevronLeft,
  ChevronRight,
  Google,
} from "react-bootstrap-icons";
import {
  nav_cursor,
  fetchKeywords,
  fetchKeywordsWithFilter,
  mapperUpdate,
  unused_keyword,
  updateKeywordMapAddress,
} from "../features/keywords/keywordSlice";
import { useDispatch, useSelector } from "react-redux";
import ButtonBox from "./ButtonBox";
import { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

const KeywordBox = ({
  key_data,
  fnModalFilter,
  fnModalManageFilter,
  fnModalRemovedKeyword,
}) => {
  const dispatch = useDispatch();
  const kList = useSelector((state) => state.keywords.keyword_list);
  const klLength = useSelector((state) => state.keywords.keyword_list.length);
  const kCursor = useSelector((state) => state.keywords.keyword_cursor);
  const id_run = useSelector((state) => state.keywords.selected_run);
  const run_list = useSelector((state) => state.keywords.run_list);
  const loadingRun = useSelector((state) => state.keywords.loading_keywords);
  const maxKeyword = useSelector((state) => state.keywords.max_keyword);
  const activeFilter = useSelector((state) => state.keywords.active_filter);
  const finishFetchUnfiltered = useSelector(
    (state) => state.keywords.finish_fetch_unfiltered
  );
  const indexFilter = useSelector(
    (state) => state.keywords.index_filter_keyword_fetch
  );
  const listKeywordFilter = useSelector(
    (state) => state.keywords.keyword_list_with_filter
  );
  const data_keyword = key_data.keyword;
  const idxa = key_data.idxa;
  // const keywordListWithFilter = useSelector(
  //   (state) => state.keywords.keyword_list_with_filter
  // );
  const listFilter = useSelector((state) => state.keywords.list_filters);
  const listMapper = useSelector((state) => state.keywords.list_mapper);
  const saveMapper = useSelector((state) => state.keywords.saveMapper);
  const savingMapper = useSelector((state) => state.keywords.savingMapper);
  let [intervalAutoSave, setIntervalAutoSave] = useState(0);
  let [fetchBG, setFetchBG] = useState(true);

  const getOffset = () => {
    let offset = 0;
    kList.forEach((obj) => {
      if (!obj.map_address || obj.map_address.length === 0) {
        offset++;
      }
    });
    return offset;
  };

  const getOffsetKeywordFiltered = () => {
    let offset = 0;
    let lkf = listKeywordFilter[indexFilter];
    if (lkf) {
      lkf.forEach((obj) => {
        if (!obj.map_address || obj.map_address.length === 0) {
          offset++;
        }
      });
    }
    return offset;
  };

  const totalKeywordLoaded = () => {
    let keywordSum = klLength;
    if (listKeywordFilter.length > 0) {
      listKeywordFilter.forEach((kf) => {
        keywordSum += kf.length;
      });
    }
    return keywordSum;
  };

  // eslint-disable-next-line
  const navKey = (opr) => {
    let k_length = totalKeywordLoaded();
    dispatch(nav_cursor({ opr: opr, keyword_loaded: k_length }));
    if (opr === 1) {
      let cur_k = kCursor + 1;
      if (k_length - cur_k === 2) {
        if (!loadingRun && !maxKeyword) {
          // if (keywordListWithFilter) {
          //   let filter_list = [];
          //   listFilter.forEach((obj) => {
          //     filter_list.push(obj.filter_name);
          //     if (obj.similarity.length > 0) {
          //       obj.similarity.forEach((sml) => {
          //         filter_list.push(sml);
          //       });
          //     }
          //   });
          //   let post_data = {
          //     id_run: id_run,
          //     offset: __offset,
          //     filter_list: filter_list,
          //   };
          //   dispatch(fetchKeywordsWithFilter(post_data));
          // } else {
          // dispatch(fetchKeywords({ _id: id_run, offset: __offset }));
          // }
          if (activeFilter === "0") {
            let __offset = getOffset();
            dispatch(fetchKeywords({ _id: id_run, offset: __offset }));
          } else {
            let __offset = getOffsetKeywordFiltered();
            let post_data = {
              id_run: id_run,
              offset: __offset,
              id_filter: activeFilter,
              limit: 2,
            };
            dispatch(fetchKeywordsWithFilter(post_data));
          }
        }
      }
    }
  };

  // eslint-disable-next-line
  const saveMap = () => {
    dispatch(mapperUpdate(listMapper));
  };

  const unusedKeyword = () => {
    // let rOffset = getOffset();
    let post_data = {
      id_keyword: data_keyword._id,
      map_address: ["Unused"],
    };
    dispatch(updateKeywordMapAddress(post_data));

    dispatch(unused_keyword());
    // if (klLength - 1 - kCursor < 2) {
    //   dispatch(fetchKeywords({ _id: idRun, offset: rOffset }));
    // }
  };

  // Key-Bind
  const handleKeyPress = useCallback(
    (event) => {
      // console.log(event.keyCode);
      if (event.ctrlKey && event.keyCode === 39) {
        navKey(1);
      }
      if (event.ctrlKey && event.keyCode === 37) {
        navKey(0);
      }
      if (event.ctrlKey && event.keyCode === 83) {
        event.preventDefault();
        saveMap();
      }
      if (event.ctrlKey && event.keyCode === 68) {
        event.preventDefault();
        unusedKeyword();
      }
    },
    [navKey, saveMap]
  );

  const bgFecthKeyword = () => {
    if (!loadingRun && !maxKeyword && fetchBG) {
      if (!finishFetchUnfiltered) {
        let __offset = getOffset();
        dispatch(fetchKeywords({ _id: id_run, offset: __offset }));
      } else {
        // console.log(listFilter[indexFilter]);
        let target_filter_id = listFilter[indexFilter]._id;
        let __offset = getOffsetKeywordFiltered();
        let post_data = {
          id_run: id_run,
          offset: __offset,
          id_filter: target_filter_id,
          limit: 2,
        };
        dispatch(fetchKeywordsWithFilter(post_data));
      }
    }
  };

  const callAutoSave = () => {
    if (intervalAutoSave > 60) {
      if (saveMapper && !savingMapper) {
        saveMap();
      }
      setIntervalAutoSave(0);
    } else {
      setIntervalAutoSave(intervalAutoSave++);
    }
  };

  const searchOnGogle = () => {
    let datasetName;
    run_list.forEach((itm) => {
      if (itm._id === id_run) {
        datasetName = itm.name.split(" ");
      }
    });
    let g_url = "https://www.google.com/search?q=";
    let country_code = datasetName.pop().toLowerCase();
    if (country_code.length > 2 || country_code === "us") {
      g_url = g_url + encodeURIComponent(data_keyword.keyword) + "&hl=en&gl=us";
    } else {
      g_url =
        g_url +
        encodeURIComponent(data_keyword.keyword) +
        "&hl=" +
        country_code +
        "&gl=" +
        country_code;
    }
    window.open(g_url, "_blank");
  };

  useEffect(() => {
    // Background keyword loader
    const update = () => {
      bgFecthKeyword();
    };
    // Autosave Interval
    const autoSave = () => {
      callAutoSave();
    };
    const interval = setInterval(() => {
      update();
      autoSave();
    }, 500);

    // Get text-active-filter-list text
    // document.getElementById("text-active-filter-list").innerHTML = "Test";

    // Key binder
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);
    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [handleKeyPress]);

  return (
    <div className='box-keyword'>
      <div className='box-keyword-top'>
        <ButtonBox
          objKeyword={data_keyword}
          fnToggle={fnModalFilter}
          fnToggleManage={fnModalManageFilter}
          fnModalRemovedKeyword={fnModalRemovedKeyword}
        />
        <div className='box-keyword-main'>
          <h5 className='mb-0'>
            <span
              className='me-2'
              onClick={() => {
                searchOnGogle();
              }}>
              <Google
                className='c_pointer text-success'
                style={{ fontSize: "14px" }}></Google>
            </span>
            {data_keyword.keyword} |{" "}
            <span className='text-danger'>{data_keyword.volume}</span>{" "}
            {listFilter[idxa] ? (
              <span
                className='text-muted small fst-italic'
                style={{ fontSize: "12px" }}>
                ( P{listFilter[idxa].priority} - {listFilter[idxa].filter_name}{" "}
                )
              </span>
            ) : (
              <span
                className='text-muted small fst-italic'
                style={{ fontSize: "12px" }}>
                ( Unfiltered )
              </span>
            )}
          </h5>
          {data_keyword.translation.length > 0 &&
          !data_keyword.translation[0].includes(
            "MYMEMORY WARNING: YOU USED ALL AVAILABLE FREE TRANSLATIONS FOR TODAY."
          ) ? (
            <>
              <span className='text-muted small fst-italic'>
                {data_keyword.translation[0]}
              </span>
            </>
          ) : (
            <>
              {" "}
              <span className='text-muted small fst-italic'>
                Translating ...
              </span>
            </>
          )}
        </div>
        <div className='box_keyword_navigation'>
          <div className='box_btn_keyword_navigation'>
            <div className='c_btn_prev'>
              <ChevronLeft
                className={kCursor === 0 ? "b_icon_disabled" : "b_icon"}
                onClick={() => {
                  navKey(0);
                }}
              />
            </div>
            <div className='c_btn_next'>
              <ChevronRight
                className={
                  kCursor < totalKeywordLoaded() - 1
                    ? "b_icon"
                    : "b_icon_disabled"
                }
                onClick={() => {
                  navKey(1);
                }}
              />
            </div>
          </div>
          <div className='flex'>
            <span className='k_amount_info small text-muted'>
              {kCursor + 1}/{totalKeywordLoaded()}
            </span>
            {loadingRun && (
              <Spinner
                className='text-success'
                style={{ fontSize: "10px", height: "10px", width: "10px" }}
                size='sm'></Spinner>
            )}
            {maxKeyword === true && <Check className='text-success'></Check>}
          </div>
        </div>
        {/* <Form.Check
          label='AF'
          type='switch'
          checked={fetchBG}
          onChange={(e) => {
            setFetchBG(!fetchBG);
          }}></Form.Check> */}
      </div>
      <div className='box-keyword-img text-break'>
        {data_keyword.b64_img &&
          data_keyword.b64_img.map((itm, i) => {
            if (i < 7) {
              return (
                <img
                  key={i}
                  src={`data:image/png;base64,${atob(itm)}`}
                  className='pvw_img'
                  alt={"ss" + i}
                />
              );
            } else {
              return <></>;
            }
          })}
      </div>
    </div>
  );
};

export default KeywordBox;
