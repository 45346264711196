import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import CorrectionPage from "./pages/Correction";
import NotFound from "./pages/NotFound";

export default function App() {
  return (
    // <BrowserRouter>
    //   <Routes>
    //     <Route
    //       path='/'
    //       element={<Layout />}>
    //       <Route
    //         index
    //         element={<Home />}
    //       />
    //       <Route
    //         path='correction'
    //         element={<CorrectionPage />}
    //       />
    //     </Route>
    //   </Routes>
    // </BrowserRouter>

    // Setup router for webapp test new staff
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={<NotFound />}></Route>
        <Route
          path='/assesment'
          element={<Layout />}>
          <Route
            index
            element={<Home />}
          />
        </Route>
        <Route
          path='*'
          element={<NotFound />}
        />
      </Routes>
    </BrowserRouter>
  );
}
