import { useState } from "react";
import { useSelector } from "react-redux";
import AlertProcess from "../components/AlertProcess";
import KeywordBox from "../components/KeywordBox";
import Mapper from "../components/Mapper";
import ModalFilter from "../components/ModalFilter";
import ModalManageFilter from "../components/ModalManageFilter";
import ModalGroupKeywordList from "../components/ModalGroupKeywordList";
import ModalAddManualMainMapItems from "../components/ModalAddManualMainMapItems";
import ImageViewer from "../components/ImageViewer";
import ModalRemovedKeywordList from "../components/ModalRemovedKeywordList";
import DatasetKeywordInormation from "../components/DatasetKeywordInormation";
import EmptyFilteredKeywordList from "../components/EmptyFilteredKeywordList";
import FilterLoader from "../components/FilterLoader";
import { useTitle } from "../components/UseTittle";

const Home = () => {
  const idRun = useSelector((state) => state.keywords.selected_run);
  const registerFilter = useSelector((state) => state.keywords.register_filter);
  const loadingRun = useSelector((state) => state.keywords.loading_keywords);
  const listKeyword = useSelector((state) => state.keywords.keyword_list);
  const listFilter = useSelector((state) => state.keywords.list_filters);
  const listFilterLoaded = useSelector(
    (state) => state.keywords.list_filter_loaded
  );
  const filteredKeywordToShow = useSelector(
    (state) => state.keywords.filtered_keyword_to_show
  );
  const kCursor = useSelector((state) => state.keywords.keyword_cursor);
  const savingMapper = useSelector((state) => state.keywords.savingMapper);
  const no_keyword_to_process = useSelector(
    (state) => state.keywords.no_keyword_to_process
  );

  // Modal
  const [filterModalShow, setFilterModalShow] = useState(false);
  const [manageFilterModalShow, setManageFilterModalShow] = useState(false);
  const [groupKeywordListModalShow, setGroupKeywordListModalShow] =
    useState(false);
  const [addManualItemMainMapModalShow, setAddManualItemMainMap] =
    useState(false);
  const [removedKeywordListModalShow, setRemovedKeywordList] = useState(false);
  const toggelFilterModal = () => {
    setFilterModalShow(!filterModalShow);
  };
  const toggelManageFilterModal = () => {
    setManageFilterModalShow(!manageFilterModalShow);
  };
  const toggelGroupKeywordListModal = () => {
    setGroupKeywordListModalShow(!groupKeywordListModalShow);
  };
  const toggelAddManualItemMainMap = () => {
    setAddManualItemMainMap(!addManualItemMainMapModalShow);
  };

  const toggleRemovedKeywordList = () => {
    setRemovedKeywordList(!removedKeywordListModalShow);
  };

  const getActiveKeyword = (objOnly) => {
    let data = {};
    let _cursor = kCursor + 1;
    if (_cursor > listKeyword.length) {
      data.idxa = filteredKeywordToShow.idxa;
      data.keyword = filteredKeywordToShow.data;
    } else {
      data.idxa = -1;
      data.keyword = listKeyword[kCursor];
    }
    if (objOnly) {
      if (no_keyword_to_process) {
        return data;
      } else {
        return data.keyword;
      }
    } else {
      return data;
    }
  };

  const listKeywordFilter = useSelector(
    (state) => state.keywords.keyword_list_with_filter
  );

  const totalKeywordLoaded = () => {
    let keywordSum = listKeyword.length;
    if (listKeywordFilter.length > 0) {
      listKeywordFilter.forEach((kf) => {
        keywordSum += kf.length;
      });
    }
    return keywordSum;
  };

  const renderElement = () => {
    // Pre-Select dataset
    if (idRun === 0 && !loadingRun) {
      return (
        <div className='alert alert-info text-center p-2 m-3'>
          Please select dataset
        </div>
      );
    }

    // Pre-Load keyword list
    if (!listFilterLoaded && idRun !== 0) {
      return <FilterLoader />;
    } else {
      if (loadingRun && totalKeywordLoaded() === 0) {
        return (
          <div className='alert alert-warning text-center p-2 m-3'>
            Loading keyword ...
          </div>
        );
      } else if (
        listKeyword.length === 0 &&
        !loadingRun &&
        listFilterLoaded &&
        totalKeywordLoaded() === 0
      ) {
        if (no_keyword_to_process) {
          return (
            <>
              <DatasetKeywordInormation></DatasetKeywordInormation>
              <div className='alert alert-success text-center p-2 m-2'>
                Horay! All keywords has been processed!
              </div>
              <hr />
              <div className='sect_mapper'>
                <Mapper
                  // keyword={getActiveKeyword().keyword}
                  // volume={getActiveKeyword().volume}
                  objKeyword={getActiveKeyword(true)}
                  togglerModalGroupKeywordList={toggelGroupKeywordListModal}
                  togglerModalAddManualItemsMainMap={toggelAddManualItemMainMap}
                  fnModalFilter={toggelFilterModal}
                  fnModalManageFilter={toggelManageFilterModal}
                  fnModalRemovedKeyword={toggleRemovedKeywordList}
                />
                <ModalGroupKeywordList
                  modalState={groupKeywordListModalShow}
                  fnToggler={toggelGroupKeywordListModal}
                />
                <ModalAddManualMainMapItems
                  modalState={addManualItemMainMapModalShow}
                  fnToggler={toggelAddManualItemMainMap}
                />
              </div>
            </>
          );
        } else {
          return <EmptyFilteredKeywordList filterList={listFilter} />;
        }
      } else if (idRun !== 0 && totalKeywordLoaded() > 0) {
        return (
          <>
            {idRun === "-1" ? (
              <>
                <div className='sect_keyword'>
                  <ImageViewer keydata={getActiveKeyword(true)} />
                </div>
              </>
            ) : (
              <>
                <DatasetKeywordInormation></DatasetKeywordInormation>
                <div className='sect_keyword'>
                  <KeywordBox
                    key_data={getActiveKeyword(false)}
                    fnModalFilter={toggelFilterModal}
                    fnModalManageFilter={toggelManageFilterModal}
                    fnModalRemovedKeyword={toggleRemovedKeywordList}
                  />
                  <div className='d-flex justify-content-center align-items-center mt-2'>
                    <p className='mb-0 text-muted text-m small'>
                      {" "}
                      --- scroll to view main map ---{" "}
                    </p>
                  </div>
                  <ModalFilter
                    modalState={filterModalShow}
                    fnToggler={toggelFilterModal}
                    keyword={getActiveKeyword(true)}
                  />
                  <ModalManageFilter
                    manageModalState={manageFilterModalShow}
                    fnManageToggler={toggelManageFilterModal}
                  />
                </div>
                <hr />
                <div className='sect_mapper'>
                  <Mapper
                    // keyword={getActiveKeyword().keyword}
                    // volume={getActiveKeyword().volume}
                    objKeyword={getActiveKeyword(true)}
                    togglerModalGroupKeywordList={toggelGroupKeywordListModal}
                    togglerModalAddManualItemsMainMap={
                      toggelAddManualItemMainMap
                    }
                    fnModalFilter={toggelFilterModal}
                    fnModalManageFilter={toggelManageFilterModal}
                    fnModalRemovedKeyword={toggleRemovedKeywordList}
                  />
                  <ModalGroupKeywordList
                    modalState={groupKeywordListModalShow}
                    fnToggler={toggelGroupKeywordListModal}
                  />
                  <ModalAddManualMainMapItems
                    modalState={addManualItemMainMapModalShow}
                    fnToggler={toggelAddManualItemMainMap}
                  />
                </div>
                <ModalRemovedKeywordList
                  modalState={removedKeywordListModalShow}
                  fnToggler={toggleRemovedKeywordList}
                />
              </>
            )}
          </>
        );
      }

      // if (loadingRun && totalKeywordLoaded() === 0) {
      //   return (
      //     <div className='alert alert-warning text-center p-2 m-3'>
      //       Loading keyword ...
      //     </div>
      //   );
      // } else if (idRun !== 0 && totalKeywordLoaded() > 0) {
      //   return (
      //     <>
      //       {idRun === "-1" ? (
      //         <>
      //           <div className='sect_keyword'>
      //             <ImageViewer keydata={getActiveKeyword(true)} />
      //           </div>
      //         </>
      //       ) : (
      //         <>
      //           <DatasetKeywordInormation></DatasetKeywordInormation>
      //           <div className='sect_keyword'>
      //             <KeywordBox
      //               key_data={getActiveKeyword(false)}
      //               fnModalFilter={toggelFilterModal}
      //               fnModalManageFilter={toggelManageFilterModal}
      //               fnModalRemovedKeyword={toggleRemovedKeywordList}
      //             />
      //             <div className='d-flex justify-content-center align-items-center mt-2'>
      //               <p className='mb-0 text-muted text-m small'>
      //                 {" "}
      //                 --- scroll to view main map ---{" "}
      //               </p>
      //             </div>
      //             <ModalFilter
      //               modalState={filterModalShow}
      //               fnToggler={toggelFilterModal}
      //               keyword={getActiveKeyword(true)}
      //             />
      //             <ModalManageFilter
      //               manageModalState={manageFilterModalShow}
      //               fnManageToggler={toggelManageFilterModal}
      //             />
      //           </div>
      //           <hr />
      //           <div className='sect_mapper'>
      //             <Mapper
      //               // keyword={getActiveKeyword().keyword}
      //               // volume={getActiveKeyword().volume}
      //               objKeyword={getActiveKeyword(true)}
      //               togglerModalGroupKeywordList={toggelGroupKeywordListModal}
      //               togglerModalAddManualItemsMainMap={toggelAddManualItemMainMap}
      //               fnModalFilter={toggelFilterModal}
      //               fnModalManageFilter={toggelManageFilterModal}
      //               fnModalRemovedKeyword={toggleRemovedKeywordList}
      //             />
      //             <ModalGroupKeywordList
      //               modalState={groupKeywordListModalShow}
      //               fnToggler={toggelGroupKeywordListModal}
      //             />
      //             <ModalAddManualMainMapItems
      //               modalState={addManualItemMainMapModalShow}
      //               fnToggler={toggelAddManualItemMainMap}
      //             />
      //           </div>
      //           <ModalRemovedKeywordList
      //             modalState={removedKeywordListModalShow}
      //             fnToggler={toggleRemovedKeywordList}
      //           />
      //         </>
      //       )}
      //     </>
      //   );
      // } else if (
      //   idRun !== 0 &&
      //   listKeyword.length === 0 &&
      //   !loadingRun &&
      //   listFilterLoaded &&
      //   totalKeywordLoaded() === 0
      // ) {
      //   return <EmptyFilteredKeywordList filterList={listFilter} />;
      //   // return (
      //   //   <div className='alert alert-warning text-center p-2 m-3'> Here </div>
      //   // );
      // } else if (
      //   idRun !== 0 &&
      //   listKeyword.length === 0 &&
      //   !loadingRun &&
      //   !listFilterLoaded &&
      //   totalKeywordLoaded() === 0
      // ) {
      //   return <FilterLoader />;
      // }
    }
  };

  useTitle("Keyword Mapper");

  return (
    <div className='c_main'>
      {savingMapper && <AlertProcess txt={"Saving map ..."} />}
      {registerFilter && <AlertProcess txt={"Registering filter ..."} />}
      {/* {loadingRun && totalKeywordLoaded() === 0 && (
        <div className='alert alert-warning text-center p-2 m-3'>
          Loading keyword ...
        </div>
      )}
      
      {idRun === 0 && !loadingRun ? (
        <div className='alert alert-info text-center p-2 m-3'>
          Please select dataset
        </div>
      ) : null}
      {idRun !== 0 && totalKeywordLoaded() > 0 ? (
        <>
          {idRun === "-1" ? (
            <>
              <div className='sect_keyword'>
                <ImageViewer keydata={getActiveKeyword(true)} />
              </div>
            </>
          ) : (
            <>
              <DatasetKeywordInormation></DatasetKeywordInormation>
              <div className='sect_keyword'>
                <KeywordBox
                  key_data={getActiveKeyword(false)}
                  fnModalFilter={toggelFilterModal}
                  fnModalManageFilter={toggelManageFilterModal}
                  fnModalRemovedKeyword={toggleRemovedKeywordList}
                />
                <div className='d-flex justify-content-center align-items-center mt-2'>
                  <p className='mb-0 text-muted text-m small'>
                    {" "}
                    --- scroll to view main map ---{" "}
                  </p>
                </div>
                <ModalFilter
                  modalState={filterModalShow}
                  fnToggler={toggelFilterModal}
                  keyword={getActiveKeyword(true)}
                />
                <ModalManageFilter
                  manageModalState={manageFilterModalShow}
                  fnManageToggler={toggelManageFilterModal}
                />
              </div>
              <hr />
              <div className='sect_mapper'>
                <Mapper
                  // keyword={getActiveKeyword().keyword}
                  // volume={getActiveKeyword().volume}
                  objKeyword={getActiveKeyword(true)}
                  togglerModalGroupKeywordList={toggelGroupKeywordListModal}
                  togglerModalAddManualItemsMainMap={toggelAddManualItemMainMap}
                  fnModalFilter={toggelFilterModal}
                  fnModalManageFilter={toggelManageFilterModal}
                  fnModalRemovedKeyword={toggleRemovedKeywordList}
                />
                <ModalGroupKeywordList
                  modalState={groupKeywordListModalShow}
                  fnToggler={toggelGroupKeywordListModal}
                />
                <ModalAddManualMainMapItems
                  modalState={addManualItemMainMapModalShow}
                  fnToggler={toggelAddManualItemMainMap}
                />
              </div>
              <ModalRemovedKeywordList
                modalState={removedKeywordListModalShow}
                fnToggler={toggleRemovedKeywordList}
              />
            </>
          )}
        </>
      ) : null}
      {idRun !== 0 &&
      listKeyword.length === 0 &&
      !loadingRun &&
      listFilterLoaded &&
      totalKeywordLoaded() === 0 ? (
        <EmptyFilteredKeywordList filterList={listFilter} />
      ) : null}
      {idRun !== 0 &&
      listKeyword.length === 0 &&
      !loadingRun &&
      !listFilterLoaded &&
      totalKeywordLoaded() === 0 ? (
        <FilterLoader />
      ) : null} */}
      {renderElement()}
    </div>
  );
};

export default Home;
