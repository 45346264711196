import React from "react";

const ImageViewer = ({ keydata }) => {
  return (
    <div className='box-keyword'>
      <div className='box-keyword-img text-break'>
        {keydata.b64_img.map((itm, i) => {
          return (
            <img
              key={i}
              src={`data:image/png;base64,${itm}`}
              className='pvw_img'
              alt={"ss" + i}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ImageViewer;
