import { Modal, Button } from "react-bootstrap";
import { ArrowCounterclockwise } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  restore_unused_keyword,
  updateKeywordMapAddress,
} from "../features/keywords/keywordSlice";

const ModalRemovedKeywordList = ({ modalState, fnToggler }) => {
  const dispatch = useDispatch();
  const removedKeywordList = useSelector(
    (state) => state.keywords.removed_keyword_list
  );

  const fToggler = () => {
    fnToggler();
  };

  const restoreKeyword = (index, keyword, destination) => {
    dispatch(
      restore_unused_keyword({ idx: index, dst: destination, kdata: keyword })
    );
    let post_data = {
      id_keyword: keyword._id,
      map_address: [],
    };
    dispatch(updateKeywordMapAddress(post_data));
  };
  return (
    <Modal
      show={modalState}
      animation={false}>
      <Modal.Header className='py-2 px-4 c_modal_header'>
        <Modal.Title>Restore Removed Keyword</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {removedKeywordList.length > 0 ? (
          <ul>
            {removedKeywordList.map((el, idx) => {
              return (
                <li key={idx}>
                  <p className='mb-1'>
                    {el.keyword_data[0].keyword} |{" "}
                    <span className='text-danger'>
                      {" "}
                      {el.keyword_data[0].volume}
                    </span>
                    <ArrowCounterclockwise
                      className='ms-3 mb-1 text-success c_pointer'
                      onClick={() => {
                        restoreKeyword(idx, el.keyword_data[0], el.destination);
                      }}
                    />
                  </p>
                </li>
              );
            })}
          </ul>
        ) : (
          <p className='small text-center text-muted'>===== Empty List =====</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='danger'
          className='btn btn-sm'
          onClick={() => fToggler()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalRemovedKeywordList;
