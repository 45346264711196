import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";

const ModalSetFilterPriority = ({ modalState, fnHandleModal }) => {
  const toggleModal = () => {
    fnHandleModal();
  };
  return (
    <Modal
      show={modalState}
      animation={false}>
      <Modal.Header className='py-2 px-4 c_modal_header'>
        <Modal.Title>Set Filter by Priority</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Priority 1:</p>
        <select
          defaultValue={0}
          className='form-select form-select-sm'>
          <option value='0'>None</option>
          <option value='0'>bla bla</option>
          <option value='0'>bla bla</option>
        </select>

        <p>Priority 2:</p>
        <select
          defaultValue={0}
          className='form-select form-select-sm'>
          <option value='0'>None</option>
          <option value='0'>bla bla</option>
          <option value='0'>bla bla</option>
        </select>

        <p>Priority 3:</p>
        <select
          defaultValue={0}
          className='form-select form-select-sm'>
          <option value='0'>None</option>
          {/* {listP3.map((obj, i) => {
            return (
              <option
                value='0'
                key={i}>
                None
              </option>
            );
          })} */}
        </select>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='success'
          className='btn btn-sm'
          onClick={() => toggleModal()}>
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSetFilterPriority;
